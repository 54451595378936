import { reactive } from "vue";

type Hotspot = {
  id: number,
  position: number[],
}

const Hotspots = reactive({
  list: [] as Hotspot[],
});

export default Hotspots;

export function setCurrentHotspots(list: Hotspot[]) {
  Hotspots.list = [...list];
}
