interface Chapter {
  scenes: Scene[];
}

interface Scene {
  en: number;
  de: number;
  [key: string]: number;
}
  
interface AudioTimingsVoice {
  chapters: Chapter[];
}

interface AudioTimingsLoops {
  chapters: Chapter[];
}

const videoTimingsVoice: AudioTimingsVoice = {
  chapters: [
    {
      scenes: [
        {
            en: 36,
            de: 36
        },
        {
            en: 69.7,
            de: 69.7
        },
        {
            en: 22,
            de: 22
        },
        {
            en: 42.7,
            de: 42.7
        },
        {
          en: 17.2,
          de: 17.2
        },
        {
          en: 41,
          de: 41
        }
      ]
    },
    {
      scenes: [
        {
            en: 30,
            de: 30
        },
        {
            en: 31.5,
            de: 32
        },
        {
            en: 48.2,
            de: 48
        },
        {
            en: 24,
            de: 24
        },
        {
            en: 17,
            de: 17
        },
        {
            en: 41.2,
            de: 41.2
        },
        {
            en: 33,
            de: 33
        },
        {
            en: 25,
            de: 25
        }
      ]
    },
    {
      scenes: [
        {
          en: 46.2,
          de: 46.2
        },
        {
          en: 23.7,
          de: 23.7
        },
        {
          en: 21.1,
          de: 21.1
        },
        {
          en: 15.4,
          de: 16.7
        },
        {
          en: 36.5,
          de: 36.5
        },
        {
          en: 33.1,
          de: 33.1
        },
        {
          en: 45.2,
          de: 46
        },
        {
          en: 25.1,
          de: 25.1
        },
        {
          en: 24.4,
          de: 26.3
        },
        {
          en: 27,
          de: 27
        },
        {
          en: 21.1,
          de: 21.1
        }
      ]
    },
    {
      scenes: [
        {
            en: 23,
            de: 23
        },
      ]
    }
  ]
};

const videoTimingsLoops: AudioTimingsLoops = {
  chapters: [
    {
      scenes: [
        {
            en: 36,
            de: 36
        },
        {
            en: 70,
            de: 70
        },
        {
            en: 22,
            de: 22
        },
        {
            en: 43.5,
            de: 43.5
        },
        {
            en: 20,
            de: 20
        },
        {
            en: 41,
            de: 41
        }
      ]
    },
    {
      scenes: [
        {
            en: 30,
            de: 30
        },
        {
            en: 33,
            de: 33
        },
        {
            en: 50,
            de: 50
        },
        {
            en: 24,
            de: 24
        },
        {
            en: 17,
            de: 17
        },
        {
            en: 42.266,
            de: 42.266
        },
        {
            en: 33.777,
            de: 33.777
        },
        {
            en: 25,
            de: 25
        }
      ]
    },
    {
      scenes: [
        {
          en: 46.2,
          de: 46.2
        },
        {
          en: 24,
          de: 24
        },
        {
          en: 22,
          de: 22
        },
        {
          en: 17,
          de: 17
        },
        {
          en: 48,
          de: 48
        },
        {
          en: 34,
          de: 34
        },
        {
          en: 46,
          de: 46
        },
        {
          en: 25.5,
          de: 25.5
        },
        {
          en: 27,
          de: 27
        },
        {
          en: 28,
          de: 28
        },
        {
          en: 22,
          de: 22
        }
      ]
    },
    {
      scenes: [
        {
            en: 23,
            de: 23
        }
      ]
    }
  ]
  };

function getVoiceEndValue(chapterId: number, sceneId: number, lang: string): number {
  const chapter = videoTimingsVoice.chapters[chapterId];
  const scene = chapter?.scenes[sceneId];
  return scene ? scene[lang] : 0;
}

function getLoopValue(chapterId: number, sceneId: number, lang: string): number {
  const chapter = videoTimingsLoops.chapters[chapterId];
  const scene = chapter?.scenes[sceneId];
  return scene ? scene[lang] : 0;
}

export { videoTimingsVoice, getVoiceEndValue, videoTimingsLoops, getLoopValue };