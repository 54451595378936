import { computed } from "vue";
import { useStoryContext } from "@/services/Composition";
import { clamp } from "@/utils/number.utils";
import content, { type ChapterIndex } from "@/config/content";

const ratioChapter = 1 / content.chapters.length;

export default function useStoryChapters() {
  const currentChapter = useStoryContext("chapter");
  const currentScene = useStoryContext("scene");
  const progress = useStoryContext("progress");
  const maxProgress = useStoryContext("maxprogress");

  const currentChapterProgress = computed(() =>
    getChapterProgress(currentChapter.value)
  );

  function getChapterProgress(chapter: ChapterIndex) {
    return clamp(progress.value / ratioChapter - chapter);
  }

  function getChapterMaxProgress(chapter: ChapterIndex) {
    return clamp(maxProgress.value / ratioChapter - chapter);
  }

  function isChapterAvailable(chapter: ChapterIndex) {
    if (currentChapter.value >= chapter) return true;

    return maxProgress.value / ratioChapter >= chapter;
  }

  function isSceneAvailable(chapter: ChapterIndex, scene: number) {
    if (!isChapterAvailable(chapter)) return false;
    if (currentScene.value >= scene) return true;

    const ratioScene = 1 / content.chapters[chapter].scenes.length;
    return (maxProgress.value / ratioChapter - chapter) / ratioScene >= scene;
  }

  function isChapterCompleted(chapter: ChapterIndex) {
    return getChapterMaxProgress(chapter) === 1;
  }

  return {
    currentChapter,
    currentScene,
    currentChapterProgress,
    getChapterProgress,
    getChapterMaxProgress,
    isChapterAvailable,
    isSceneAvailable,
    isChapterCompleted,
  };
}
