import Chunk from "nanogl-pbr/Chunk";
import Texture2D from "nanogl/texture-2d";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input, { ShaderType, Uniform } from "nanogl-pbr/Input";

import CreateShader from "@webgl/core/CreateProgram";
import { BLACK, YELLOW } from "@webgl/lib/constants";

import VertModule from "./tp_button.vert";
import FragModule from "./tp_button.frag";

const VertCode = CreateShader(VertModule);
const FragCode = CreateShader(FragModule);

export default class TpButtonChunk extends Chunk {
  hover: Input;
  texture: Input;
  visibility: Input;
  hoverUniform: Uniform;
  colorPrimary: Input;
  colorSecondary: Input;
  visibilityUniform: Uniform;

  constructor(texture?: Texture2D) {
    super(true, false);

    this.addChild((this.hover = new Input("uHover", 1, ShaderType.FRAGMENT)));
    this.addChild((this.texture = new Input("uTexture", 3, ShaderType.FRAGMENT)));
    this.addChild((this.visibility = new Input("uVisibility", 1, ShaderType.FRAGMENT)));
    this.addChild((this.colorPrimary = new Input("uColorPrimary", 3, ShaderType.FRAGMENT)));
    this.addChild((this.colorSecondary = new Input("uColorSecondary", 3, ShaderType.FRAGMENT)));

    const sampler = this.texture.attachSampler("uTexture", "vTexCoord");
    if (texture) sampler.set(texture);

    this.hoverUniform = this.hover.attachUniform();
    this.visibilityUniform = this.visibility.attachUniform();

    this.colorPrimary.attachConstant(YELLOW);
    this.colorSecondary.attachConstant(BLACK);

    VertCode.onHmr(() => this.invalidateCode());
    FragCode.onHmr(() => this.invalidateCode());
  }

  protected _genCode(slots: ChunksSlots): void {
    slots.add("pv", VertCode({ slot: "pv" }));
    slots.add("postv", VertCode({ slot: "postv" }));
    slots.add("pf", FragCode({ slot: "pf" }));
    slots.add("postf", FragCode({ slot: "postf" }));
  }

  dispose() { }
}