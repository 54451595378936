import AppService from "@/services/AppService";
import { MUX_STREAM_URL } from "@/config/constants";
import chapters from "./chapters";
import {
  HotSpotMediaType,
  type ContentType,
  type HotSpotType,
  type MediaContent,
  type MapContent,
} from "./types";
export * from "./types";

// Content Chapters
const ctn: ContentType = { chapters };

export default ctn;

// Some helpers

function getChapter(chapter: number) {
  return ctn.chapters[chapter];
}
function getScene(chapter: number, scene: number) {
  return ctn.chapters[chapter].scenes[scene];
}

// Get the type of a hotspot at chapter, scene and hotspot id
function getSceneHotspotType(chapter: number, scene: number, id = 0) {
  return getScene(chapter, scene).hotspots[id].type;
}

// Return an array of hotspot types for a scene
function getSceneHotspotsType(chapter: number, scene: number) {
  return getScene(chapter, scene).hotspots.map(hp => hp.type);
}

// Return an array of all contents for the current scene
function getCurrentContent<TContent extends MediaContent | MapContent>() {
  return ctn.chapters[AppService.getStoryContext().chapter].scenes[
    AppService.getStoryContext().scene
  ].hotspots as HotSpotType<TContent>[];
}

function getHotSpotByType(hpType: HotSpotMediaType) {
  const all = [];
  for (const chapter of ctn.chapters) {
    for (const scene of chapter.scenes) {
      if (scene.hotspots?.some(hp => hp.type === hpType)) all.push(scene);
    }
  }

  return all;
}

function typeIsMap(hpType: HotSpotMediaType) {
  return hpType === HotSpotMediaType.MAP || hpType === HotSpotMediaType.MAP_EUROPE || hpType === HotSpotMediaType.MAP_1;
}

const muxManifestUrl = (playbackId: string, options = "") =>
  `${MUX_STREAM_URL}${playbackId}.m3u8${options}`;

export {
  getChapter,
  getScene,
  getSceneHotspotType,
  getSceneHotspotsType,
  getHotSpotByType,
  getCurrentContent,
  typeIsMap,
  muxManifestUrl,
};
