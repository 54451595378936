import { HotSpotMediaType, type ChapterType } from "./types";

const chapters: ChapterType[] = [{
  titlekey: "chapter.1.title",
  datekey: "chapter.1.date",
  image: {
    src: "/assets/content/generated/Chapter1/1.image",
    width: 999,
    height: 684,
  },
  scenes: [// Scene 1
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.1.scene.1.label",
        content: [{
          text: {
            titlekey: "chapter.1.scene.1.slide.1.title",
            textkey: "chapter.1.scene.1.slide.1.description",
            captionkey: "chapter.1.scene.1.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.1.slide/1.scene.1.slide.1",
            width: 2048,
            height: 1536,
          },
          video: {
            src: "01xIdqbBk48FaAw7nHzGH5exZN01nNMPS01xJRo571c76I",
            width: 1440,
            height: 1080,
          },
        },]
      },],
    },// Scene 2
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.1.scene.2.label",
        content: [{
          text: {
            titlekey: "chapter.1.scene.2.slide.1.title",
            textkey: "chapter.1.scene.2.slide.1.description",
            captionkey: "chapter.1.scene.2.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.1",
            width: 2048,
            height: 1321,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.2.slide.2.title",
            textkey: "chapter.1.scene.2.slide.2.description",
            captionkey: "chapter.1.scene.2.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.2",
            width: 2048,
            height: 1471,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.2.slide.3.title",
            textkey: "chapter.1.scene.2.slide.3.description",
            captionkey: "chapter.1.scene.2.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.3",
            width: 2048,
            height: 1574,
          },
          video: {
            src: "yqbL7ouzH5VK5Vway6zY401C7qFU4SXraBwCUKaw01jqo",
            width: 1920,
            height: 1440,
          },
        }, {
          text: {
            titlekey: "chapter.1.scene.2.slide.4.title",
            textkey: "chapter.1.scene.2.slide.4.description",
            captionkey: "chapter.1.scene.2.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.4",
            width: 700,
            height: 468,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.2.slide.5.title",
            textkey: "chapter.1.scene.2.slide.5.description",
            captionkey: "chapter.1.scene.2.slide.5.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.5",
            width: 1300,
            height: 1096,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.2.slide.6.title",
            textkey: "chapter.1.scene.2.slide.6.description",
            captionkey: "chapter.1.scene.2.slide.6.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.2.slide/1.scene.2.slide.6",
            width: 1247,
            height: 2048,
          },

        },]
      },],
    },// Scene 3
    {
    },// Scene 4
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.1.scene.4.label",
        content: [{
          text: {
            titlekey: "chapter.1.scene.4.slide.1.title",
            textkey: "chapter.1.scene.4.slide.1.description",
            captionkey: "chapter.1.scene.4.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.4.slide/1.scene.4.slide.1",
            width: 1340,
            height: 2048,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.4.slide.2.title",
            textkey: "chapter.1.scene.4.slide.2.description",
            captionkey: "chapter.1.scene.4.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.4.slide/1.scene.4.slide.2",
            width: 1471,
            height: 2048,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.4.slide.3.title",
            textkey: "chapter.1.scene.4.slide.3.description",
            captionkey: "chapter.1.scene.4.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.4.slide/1.scene.4.slide.3",
            width: 2048,
            height: 1340,
          },

        }, {
          text: {
            titlekey: "chapter.1.scene.4.slide.4.title",
            textkey: "chapter.1.scene.4.slide.4.description",
            captionkey: "chapter.1.scene.4.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.4.slide/1.scene.4.slide.4",
            width: 1601,
            height: 2048,
          },

        },]
      },],
    },// Scene 5
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.1.scene.5.label",
        content: [{
          text: {
            titlekey: "chapter.1.scene.5.slide.1.title",
            textkey: "chapter.1.scene.5.slide.1.description",
            captionkey: "chapter.1.scene.5.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.5.slide/1.scene.5.slide.1",
            width: 2048,
            height: 1533,
          },
          video: {
            src: "3lR2QA5oQPUxni008IlPuLQFbj3gVqTSzxyoWKgboug8",
            width: 1920,
            height: 1440,
          },
        }, {
          text: {
            titlekey: "chapter.1.scene.5.slide.2.title",
            textkey: "chapter.1.scene.5.slide.2.description",
            captionkey: "chapter.1.scene.5.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter1/1.scene.5.slide/1.scene.5.slide.2",
            width: 1024,
            height: 778,
          },

        },]
      },],
    },// Scene 6
    {
      hotspots: [{
        type: HotSpotMediaType.MAP,
        labelkey: "chapter.1.scene.6.label",
        content: {

          introText: {
            titlekey: "chapter.1.scene.6.map.title",
            textkey: "chapter.1.scene.6.map.description",
            datekey: "chapter.1.scene.6.map.date",
          },
          hotspots: [
            {
              id: "berlin",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.1.place",
                captionkey: "chapter.1.scene.6.map.hotspot.1.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.1.title",
                textkey: "chapter.1.scene.6.map.hotspot.1.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.1",
                width: 800,
                height: 597,
              },


            },

            {
              id: "chemnitz",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.2.place",
                captionkey: "chapter.1.scene.6.map.hotspot.2.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.2.title",
                textkey: "chapter.1.scene.6.map.hotspot.2.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.2",
                width: 574,
                height: 800,
              },


            },


            {
              id: "koenigsberg",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.3.place",
                captionkey: "chapter.1.scene.6.map.hotspot.3.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.3.title",
                textkey: "chapter.1.scene.6.map.hotspot.3.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.3",
                width: 800,
                height: 577,
              },


            },

            {
              id: "teplice-sanov",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.4.place",
                captionkey: "chapter.1.scene.6.map.hotspot.4.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.4.title",
                textkey: "chapter.1.scene.6.map.hotspot.4.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.4",
                width: 2048,
                height: 1536,
              },


            },

            {
              id: "vienna",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.5.place",
                captionkey: "chapter.1.scene.6.map.hotspot.5.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.5.title",
                textkey: "chapter.1.scene.6.map.hotspot.5.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.5",
                width: 437,
                height: 596,
              },


            },

            {
              id: "hamburg",
              text: {
                placekey: "chapter.1.scene.6.map.hotspot.6.place",
                captionkey: "chapter.1.scene.6.map.hotspot.6.caption",
                titlekey: "chapter.1.scene.6.map.hotspot.6.title",
                textkey: "chapter.1.scene.6.map.hotspot.6.description",
              },
              image: {
                src: "/assets/content/generated/Chapter1/1.scene.6.map/1.scene.6.map.hotspot.6",
                width: 822,
                height: 537,
              },


            },
          ],
        }
      },],
    },],
}, {
  titlekey: "chapter.2.title",
  datekey: "chapter.2.date",
  image: {
    src: "/assets/content/generated/Chapter2/2.image",
    width: 1000,
    height: 715,
  },
  scenes: [// Scene 1
    {
    },// Scene 2
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.2.scene.2.label",
        content: [{
          text: {
            titlekey: "chapter.2.scene.2.slide.1.title",
            textkey: "chapter.2.scene.2.slide.1.description",
            captionkey: "chapter.2.scene.2.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.2.slide/2.scene.2.slide.1",
            width: 2048,
            height: 1451,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.2.slide.2.title",
            textkey: "chapter.2.scene.2.slide.2.description",
            captionkey: "chapter.2.scene.2.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.2.slide/2.scene.2.slide.2",
            width: 2048,
            height: 1493,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.2.slide.3.title",
            textkey: "chapter.2.scene.2.slide.3.description",
            captionkey: "chapter.2.scene.2.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.2.slide/2.scene.2.slide.3",
            width: 2048,
            height: 1481,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.2.slide.4.title",
            textkey: "chapter.2.scene.2.slide.4.description",
            captionkey: "chapter.2.scene.2.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.2.slide/2.scene.2.slide.4",
            width: 1476,
            height: 2048,
          },

        },]
      },],
    },// Scene 3
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.2.scene.3.label",
        content: [{
          text: {
            titlekey: "chapter.2.scene.3.slide.1.title",
            textkey: "chapter.2.scene.3.slide.1.description",
            captionkey: "chapter.2.scene.3.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.3.slide/2.scene.3.slide.1",
            width: 2048,
            height: 1318,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.3.slide.2.title",
            textkey: "chapter.2.scene.3.slide.2.description",
            captionkey: "chapter.2.scene.3.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.3.slide/2.scene.3.slide.2",
            width: 2048,
            height: 1522,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.3.slide.3.title",
            textkey: "chapter.2.scene.3.slide.3.description",
            captionkey: "chapter.2.scene.3.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.3.slide/2.scene.3.slide.3",
            width: 1165,
            height: 760,
          },

        },]
      },],
    },// Scene 4
    {
    },// Scene 5
    {
    },// Scene 6
    {
    },// Scene 7
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.2.scene.7.label",
        content: [{
          text: {
            titlekey: "chapter.2.scene.7.slide.1.title",
            textkey: "chapter.2.scene.7.slide.1.description",
            captionkey: "chapter.2.scene.7.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.7.slide/2.scene.7.slide.1",
            width: 2048,
            height: 1383,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.7.slide.2.title",
            textkey: "chapter.2.scene.7.slide.2.description",
            captionkey: "chapter.2.scene.7.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.7.slide/2.scene.7.slide.2",
            width: 2048,
            height: 1395,
          },

        }]
      },],
    },// Scene 8
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.2.scene.8.label",
        content: [{
          text: {
            titlekey: "chapter.2.scene.8.slide.1.title",
            textkey: "chapter.2.scene.8.slide.1.description",
            captionkey: "chapter.2.scene.8.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.8.slide/2.scene.8.slide.1",
            width: 2048,
            height: 1365,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.8.slide.2.title",
            textkey: "chapter.2.scene.8.slide.2.description",
            captionkey: "chapter.2.scene.8.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.8.slide/2.scene.8.slide.2",
            width: 1518,
            height: 2048,
          },

        }, {
          text: {
            titlekey: "chapter.2.scene.8.slide.3.title",
            textkey: "chapter.2.scene.8.slide.3.description",
            captionkey: "chapter.2.scene.8.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter2/2.scene.8.slide/2.scene.8.slide.3",
            width: 1024,
            height: 1432,
          },

        },]
      }, {
        type: HotSpotMediaType.MAP_1,
        labelkey: "chapter.2.scene.8.label",
        content: {

          introText: {
            titlekey: "chapter.2.scene.8.map.title",
            textkey: "chapter.2.scene.8.map.description",
            datekey: "chapter.2.scene.8.map.date",
          },
          hotspots: [
            {
              id: "berlin1",
              text: {
                placekey: "chapter.2.scene.8.map.hotspot.1.place",
                captionkey: "chapter.2.scene.8.map.hotspot.1.caption",
                titlekey: "chapter.2.scene.8.map.hotspot.1.title",
                textkey: "chapter.2.scene.8.map.hotspot.1.description",
              },
              image: {
                src: "/assets/content/generated/Chapter2/2.scene.8.map/2.scene.8.map.hotspot.1",
                width: 2048,
                height: 1534,
              },
              video: {
                src: "Gq88clY77aVGIaL4FoaNEAnV015aQt01pGc1wr02VZBWxE",
                width: 1920,
                height: 1440,
              },

            },

            {
              id: "baden-baden",
              text: {
                placekey: "chapter.2.scene.8.map.hotspot.2.place",
                captionkey: "chapter.2.scene.8.map.hotspot.2.caption",
                titlekey: "chapter.2.scene.8.map.hotspot.2.title",
                textkey: "chapter.2.scene.8.map.hotspot.2.description",
              },
              image: {
                src: "/assets/content/generated/Chapter2/2.scene.8.map/2.scene.8.map.hotspot.2",
                width: 2048,
                height: 1534,
              },
              video: {
                src: "yF44tfkgTRUzz9Uw8LSpBDIzTFyROHixHyiO00GqG2dY",
                width: 1920,
                height: 1440,
              },

            },

            {
              id: "vienna0",
              text: {
                placekey: "chapter.2.scene.8.map.hotspot.3.place",
                captionkey: "chapter.2.scene.8.map.hotspot.3.caption",
                titlekey: "chapter.2.scene.8.map.hotspot.3.title",
                textkey: "chapter.2.scene.8.map.hotspot.3.description",
              },
              image: {
                src: "/assets/content/generated/Chapter2/2.scene.8.map/2.scene.8.map.hotspot.3",
                width: 2048,
                height: 1534,
              },
              video: {
                src: "cMpjUwraGWwS00tCu19fdR01xXivBjIut57diFyexDZpI",
                width: 1920,
                height: 1440,
              },

            },

            {
              id: "nuremberg",
              text: {
                placekey: "chapter.2.scene.8.map.hotspot.4.place",
                captionkey: "chapter.2.scene.8.map.hotspot.4.caption",
                titlekey: "chapter.2.scene.8.map.hotspot.4.title",
                textkey: "chapter.2.scene.8.map.hotspot.4.description",
              },
              image: {
                src: "/assets/content/generated/Chapter2/2.scene.8.map/2.scene.8.map.hotspot.4",
                width: 2048,
                height: 1534,
              },
              video: {
                src: "itQ501oWYBKbwBz2DrdBEG2TEmLNtiJEENOPINa42Ta8",
                width: 1920,
                height: 1440,
              },

            },

            {
              id: "vienna1",
              text: {
                placekey: "chapter.2.scene.8.map.hotspot.5.place",
                captionkey: "chapter.2.scene.8.map.hotspot.5.caption",
                titlekey: "chapter.2.scene.8.map.hotspot.5.title",
                textkey: "chapter.2.scene.8.map.hotspot.5.description",
              },
              image: {
                src: "/assets/content/generated/Chapter2/2.scene.8.map/2.scene.8.map.hotspot.5",
                width: 2048,
                height: 1534,
              },
              video: {
                src: "9nD10202cadefkBeCWexoyMZ9Sa4Wmf8mseSCJ7016NaZ4",
                width: 1920,
                height: 1440,
              },

            },
          ],
        }
      },],
    },],
}, {
  titlekey: "chapter.3.title",
  datekey: "chapter.3.date",
  image: {
    src: "/assets/content/generated/Chapter3/3.image",
    width: 716,
    height: 999,
  },
  scenes: [// Scene 1
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.3.scene.1.label",
        content: [{
          text: {
            titlekey: "chapter.3.scene.1.slide.1.title",
            textkey: "chapter.3.scene.1.slide.1.description",
            captionkey: "chapter.3.scene.1.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.1.slide/3.scene.1.slide.1",
            width: 520,
            height: 369,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.1.slide.2.title",
            textkey: "chapter.3.scene.1.slide.2.description",
            captionkey: "chapter.3.scene.1.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.1.slide/3.scene.1.slide.2",
            width: 520,
            height: 370,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.1.slide.3.title",
            textkey: "chapter.3.scene.1.slide.3.description",
            captionkey: "chapter.3.scene.1.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.1.slide/3.scene.1.slide.3",
            width: 999,
            height: 712,
          },

        },]
      },],
    },// Scene 2
    {
    },// Scene 3
    {
    },// Scene 4
    {
    },// Scene 5
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.3.scene.5.label",
        content: [{
          text: {
            titlekey: "chapter.3.scene.5.slide.1.title",
            textkey: "chapter.3.scene.5.slide.1.description",
            captionkey: "chapter.3.scene.5.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.5.slide/3.scene.5.slide.1",
            width: 1024,
            height: 727,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.5.slide.2.title",
            textkey: "chapter.3.scene.5.slide.2.description",
            captionkey: "chapter.3.scene.5.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.5.slide/3.scene.5.slide.2",
            width: 700,
            height: 509,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.5.slide.3.title",
            textkey: "chapter.3.scene.5.slide.3.description",
            captionkey: "chapter.3.scene.5.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.5.slide/3.scene.5.slide.3",
            width: 2048,
            height: 1261,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.5.slide.4.title",
            textkey: "chapter.3.scene.5.slide.4.description",
            captionkey: "chapter.3.scene.5.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.5.slide/3.scene.5.slide.4",
            width: 357,
            height: 240,
          },

        },]
      },],
    },// Scene 6
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.3.scene.6.label",
        content: [{
          text: {
            titlekey: "chapter.3.scene.6.slide.1.title",
            textkey: "chapter.3.scene.6.slide.1.description",
            captionkey: "chapter.3.scene.6.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.6.slide/3.scene.6.slide.1",
            width: 1543,
            height: 2048,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.6.slide.2.title",
            textkey: "chapter.3.scene.6.slide.2.description",
            captionkey: "chapter.3.scene.6.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.6.slide/3.scene.6.slide.2",
            width: 2048,
            height: 1495,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.6.slide.3.title",
            textkey: "chapter.3.scene.6.slide.3.description",
            captionkey: "chapter.3.scene.6.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.6.slide/3.scene.6.slide.3",
            width: 2048,
            height: 961,
          },

        }, {
          text: {
            titlekey: "chapter.3.scene.6.slide.4.title",
            textkey: "chapter.3.scene.6.slide.4.description",
            captionkey: "chapter.3.scene.6.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.6.slide/3.scene.6.slide.4",
            width: 2048,
            height: 1043,
          },

        },]
      },],
    },// Scene 7
    {
    },// Scene 8
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.3.scene.8.label",
        content: [{
          text: {
            titlekey: "chapter.3.scene.8.slide.1.title",
            textkey: "chapter.3.scene.8.slide.1.description",
            captionkey: "chapter.3.scene.8.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.8.slide/3.scene.8.slide.1",
            width: 2048,
            height: 1534,
          },
          video: {
            src: "7dKCWrljro76EMaH6ZX7CrOU7hSJrW8xp4peBbk7dMY",
            width: 1920,
            height: 1440,
          },
        },]
      },],
    },// Scene 9
    {
    },// Scene 10
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.3.scene.10.label",
        content: [{
          text: {
            titlekey: "chapter.3.scene.10.slide.1.title",
            textkey: "chapter.3.scene.10.slide.1.description",
            captionkey: "chapter.3.scene.10.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter3/3.scene.10.slide/3.scene.10.slide.1",
            width: 2048,
            height: 1534,
          },
          video: {
            src: "boQnGDlNmYxcoCzCWU4R4TFQYeWcW6Eq3iRQPK15vIo",
            width: 1920,
            height: 1440,
          },
        },]
      },],
    },// Scene 11
    {
      hotspots: [{
        type: HotSpotMediaType.MAP_EUROPE,
        labelkey: "chapter.3.scene.11.label",
        content: {

          introText: {
            titlekey: "chapter.3.scene.11.map.title",
            textkey: "chapter.3.scene.11.map.description",
            datekey: "chapter.3.scene.11.map.date",
          },
          hotspots: [
            {
              id: "sarajevo",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.1.place",
                captionkey: "chapter.3.scene.11.map.hotspot.1.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.1.title",
                textkey: "chapter.3.scene.11.map.hotspot.1.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.10",
                width: 352,
                height: 240,
              },


            },

            {
              id: "oslo",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.2.place",
                captionkey: "chapter.3.scene.11.map.hotspot.2.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.2.title",
                textkey: "chapter.3.scene.11.map.hotspot.2.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.2",
                width: 141,
                height: 182,
              },


            },

            {
              id: "kassel",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.3.place",
                captionkey: "chapter.3.scene.11.map.hotspot.3.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.3.title",
                textkey: "chapter.3.scene.11.map.hotspot.3.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.3",
                width: 352,
                height: 288,
              },


            },

            {
              id: "vienna2",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.4.place",
                captionkey: "chapter.3.scene.11.map.hotspot.4.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.4.title",
                textkey: "chapter.3.scene.11.map.hotspot.4.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.4",
                width: 299,
                height: 401,
              },


            },

            {
              id: "saukenai",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.5.place",
                captionkey: "chapter.3.scene.11.map.hotspot.5.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.5.title",
                textkey: "chapter.3.scene.11.map.hotspot.5.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.5",
                width: 709,
                height: 513,
              },


            },

            {
              id: "rome",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.6.place",
                captionkey: "chapter.3.scene.11.map.hotspot.6.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.6.title",
                textkey: "chapter.3.scene.11.map.hotspot.6.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.6",
                width: 262,
                height: 240,
              },


            },

            {
              id: "budapest",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.7.place",
                captionkey: "chapter.3.scene.11.map.hotspot.7.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.7.title",
                textkey: "chapter.3.scene.11.map.hotspot.7.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.7",
                width: 858,
                height: 995,
              },


            },

            {
              id: "athens",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.8.place",
                captionkey: "chapter.3.scene.11.map.hotspot.8.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.8.title",
                textkey: "chapter.3.scene.11.map.hotspot.8.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.8",
                width: 720,
                height: 480,
              },


            },

            {
              id: "lodz",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.9.place",
                captionkey: "chapter.3.scene.11.map.hotspot.9.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.9.title",
                textkey: "chapter.3.scene.11.map.hotspot.9.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.9",
                width: 352,
                height: 240,
              },


            },

            {
              id: "craciunesti",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.10.place",
                captionkey: "chapter.3.scene.11.map.hotspot.10.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.10.title",
                textkey: "chapter.3.scene.11.map.hotspot.10.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.10",
                width: 352,
                height: 240,
              },


            },

            {
              id: "paris",
              text: {
                placekey: "chapter.3.scene.11.map.hotspot.11.place",
                captionkey: "chapter.3.scene.11.map.hotspot.11.caption",
                titlekey: "chapter.3.scene.11.map.hotspot.11.title",
                textkey: "chapter.3.scene.11.map.hotspot.11.description",
              },
              image: {
                src: "/assets/content/generated/Chapter3/3.scene.11.map/3.scene.11.map.hotspot.11",
                width: 299,
                height: 396,
              },


            },
          ],
        }
      },],
    },],
}, {
  titlekey: "chapter.4.title",
  datekey: "chapter.4.date",
  image: {
    src: "/assets/content/generated/Chapter4/4.image",
    width: 2048,
    height: 1365,
  },
  scenes: [// Scene 1
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.4.scene.1.label",
        content: [{
          text: {
            titlekey: "chapter.4.scene.1.slide.1.title",
            textkey: "chapter.4.scene.1.slide.1.description",
            captionkey: "chapter.4.scene.1.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.1.slide/4.scene.1.slide.1",
            width: 2048,
            height: 1365,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.1.slide.2.title",
            textkey: "chapter.4.scene.1.slide.2.description",
            captionkey: "chapter.4.scene.1.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.1.slide/4.scene.1.slide.2",
            width: 2048,
            height: 1038,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.1.slide.3.title",
            textkey: "chapter.4.scene.1.slide.3.description",
            captionkey: "chapter.4.scene.1.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.1.slide/4.scene.1.slide.3",
            width: 2048,
            height: 1363,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.1.slide.4.title",
            textkey: "chapter.4.scene.1.slide.4.description",
            captionkey: "chapter.4.scene.1.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.1.slide/4.scene.1.slide.4",
            width: 1621,
            height: 2048,
          },

        },]
      },],
    },// Scene 2
    {
      hotspots: [{
        type: HotSpotMediaType.MEDIA,
        labelkey: "chapter.4.scene.2.label",
        content: [{
          text: {
            titlekey: "chapter.4.scene.2.slide.1.title",
            textkey: "chapter.4.scene.2.slide.1.description",
            captionkey: "chapter.4.scene.2.slide.1.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.1",
            width: 999,
            height: 719,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.2.slide.2.title",
            textkey: "chapter.4.scene.2.slide.2.description",
            captionkey: "chapter.4.scene.2.slide.2.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.2",
            width: 2048,
            height: 1363,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.2.slide.3.title",
            textkey: "chapter.4.scene.2.slide.3.description",
            captionkey: "chapter.4.scene.2.slide.3.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.3",
            width: 1024,
            height: 683,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.2.slide.4.title",
            textkey: "chapter.4.scene.2.slide.4.description",
            captionkey: "chapter.4.scene.2.slide.4.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.4",
            width: 2048,
            height: 1365,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.2.slide.5.title",
            textkey: "chapter.4.scene.2.slide.5.description",
            captionkey: "chapter.4.scene.2.slide.5.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.5",
            width: 2048,
            height: 1365,
          },

        }, {
          text: {
            titlekey: "chapter.4.scene.2.slide.6.title",
            textkey: "chapter.4.scene.2.slide.6.description",
            captionkey: "chapter.4.scene.2.slide.6.caption"
          },
          image: {
            src: "/assets/content/generated/Chapter4/4.scene.2.slide/4.scene.2.slide.6",
            width: 454,
            height: 544,
          },

        },]
      },],
    },],
},];
export default chapters;
