import AppService from "@/services/AppService";
import { ActivityId } from "@webgl/activities/ActivityRegistry";
import { localStorageGet, localStorageSet, startActivity, stopActivity } from "./AppStateMachine";
import { StoryEvent, StoryGoTo, StoryMediaOpen, StoryProgress } from "./StoryEvents";
import { State, createMachine, pure, sendParent, assign } from "xstate";
import content, { HotSpotMediaType, getSceneHotspotType, type ChapterIndex } from "@/config/content";
import { getLocale } from "@/config/getLocale";
import { getReadingTiming, getVideoTiming } from "@webgl/activities/IntroStory/videoTimings";
import { MEDIA_CLOSING_DURATION } from "@/config/constants";
import Delay from "@/core/Delay";
import AudioManager, { AUDIO_ID } from "@webgl/core/audio/AudioManager";
import Viewport from "@/store/modules/Viewport";

export enum StoryStateEnum {
  INACTIVE = "inactive",
}


export type StoryStateContext = {
  chapter: ChapterIndex,
  scene: number,
  content: StoryHotSpot[],
  showXRTutorial: boolean,
  isMenuOpen: boolean,
  canGoNext: boolean,
  progress: number,
  maxprogress: number,
  sceneprogress: number,
  currHotspotId: number,
  video360Played: boolean,
  video360Authorized: boolean,
}

export type StoryStateType = {
  value: string
  context: StoryStateContext
}

export type StoryStateEvent = {
  type: ""
}

export type StoryState = State<StoryStateContext, StoryEvent, StoryStateType>


function checkIsActive(id: ActivityId) {
  return AppService.Scene.activities._activeList.includes(id);
}

export const LS_VAR = "storyProgress";

export function transformProgress(progress: number) {
  const totalChapters = content.chapters.length;
  const totalScenesInChapter = (chapter: number) => content.chapters[chapter].scenes.length;


  // Calculer le numéro de chapitre
  const chapter = Math.floor(progress * totalChapters);

  // Calculer le pourcentage de progression dans le chapitre
  const percChapter = chapter / totalChapters;

  // Calculer le pourcentage de progression dans les scènes du chapitre
  const remainingProgressAfterChapter = progress - percChapter;
  const ratioChapter = 1 / totalChapters;
  const sceneProgressInChapter = remainingProgressAfterChapter / ratioChapter;

  // Calculer le numéro de scène
  const scenesInCurrentChapter = totalScenesInChapter(chapter);
  const scene = Math.floor(sceneProgressInChapter * scenesInCurrentChapter);

  // Calculer le pourcentage de progression dans la scène
  const percScenes = scene / scenesInCurrentChapter;
  const remainingProgressAfterScenes = sceneProgressInChapter - percScenes;
  const ratioScene = 1 / scenesInCurrentChapter;
  const sceneProgress = remainingProgressAfterScenes / ratioScene;

  return { chapter, scene, sceneProgress };
}

export function calculateProgress(chapter: number, scene: number, sceneProgress: number) {

  const ratioChapter = 1 / content.chapters.length;
  const ratioScene = 1 / content.chapters[chapter].scenes.length;
  const percChapter = chapter / content.chapters.length;
  const percScenes = ratioChapter * scene / content.chapters[chapter].scenes.length

  const percScene = ratioChapter * ratioScene * sceneProgress;

  return percChapter + percScenes + percScene;
}

/////////////
/////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////
 
///////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////
 
///////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////
 
/////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////
 
//////////

const StoryStateMachine = function() {
  return createMachine<StoryStateContext, StoryEvent, StoryStateType>
    (
      {
        id: "story",
        initial: "initial",
        context: {
          chapter: 0,
          scene: 0,
          content: [

          ],
          showXRTutorial: process.env.VUE_APP_SKIP_XR_TUTORIAL !== "true",
          isMenuOpen: false,
          canGoNext: false,
          progress: 0,
          maxprogress: 0,
          sceneprogress: 0,
          currHotspotId: 0,
          video360Played: false,
          video360Authorized: false,
        },
        on: {
          INIT_STORY: {
            actions: ["checkJump"],
            target: "checkJump"
          },
          OPEN_MENU: {
            actions: "openMenu"
          },
          CLOSE_MENU: {
            actions: "closeMenu"
          },
        },
        states: {
          initial: {
          },
          checkJump: {
            always: [
///////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////
              { target: "chapterIntro" }
            ]
          },
          chapterIntro: {
            entry: ["startChapterIntro", "setProgress"],
            exit: ["stopChapterIntro"],
            initial: "intro",
            on: {
              NEXT: "story",
            },
            states: {
              intro: {
                after: {
///////////////////////////////
///////////////////////////////////////////////////////////////
////////////////////////////
                  3000: { target: "title", cond: "isFirstChapter" },
                  4000: "title"
                }
              },
              title: {
                entry: ["startChapterStoryIntro"], // make shards after title arrive faster
                after: {
///////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////
                  4000: "introStory"
                }
              },
              introStory: {
                entry: ["startXrSubs"],
                exit: ["stopXrSubs"],
                on: {
                  SKIP: "videoIntroStory"
                },
                after: {
///////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////
                  INTRO_READING_TIMING: { target: "videoIntroStory", cond: "notIsMobile" }
                }
              },
              videoIntroStory: {
                after: {
                  INTRO_VIDEO_TIMING: "exitIntroStory"
                }
              },
              exitIntroStory: {
                exit: ["stopChapterStoryIntro"],
                on: {
                  NEXT: "#story.story"
                },
                after: {
                }
              }
            }
          },
          story: {
            entry: ["startCurrentScene"],
            initial: "travel",
            states: {
              travel: {
                on: {
                  TRAVEL_END: "scene"
                }
              },
              scene: {
                initial: "default",
                on: {
                  OPEN_DIT: { actions: ["openDIT"], target: "ditWait" },
                },
                states: {
                  default: {
                    initial: "playing",
                    entry: ["initCanGoNext"],
                    exit: ["stopChapterUI"],
                    states: {
                      playing: {
                        entry: ["startXrSubs"],
                        exit: ["stopXrSubs"],
                        initial: "default",
                        on: {
                          ANIM_END: "ended",
                          PROGRESS: { actions: ["setProgress"] }
                        },
                        states: {
                          default: {
                            always: [
                              { target: "showContinue", cond: "isVideo360Mobile" }
                            ],
                          },
                          showContinue: {
                            on: {
                              SKIP: "continue"
                            },
                          },
                          continue: {
                            entry: ["video360Authorized"],
                            after: {
                              10: "default"
                            }
                          }
                        }
                      },
                      ended: {
                        entry: ["enableNext"],
                      }
                    },
                    on: {
                      OPEN_MEDIA: {
                        target: "mediaopening",
                      },
                      GO_TO: {
                        actions: ["changeScene"],
                        target: "changeScene"
                      },
                      VIDEO_END: {
                        actions: ["setVideo360Played"],
                        target: "#story.end"
                      },
                      NEXT_SCENE: [
                        { actions: ["incrementScene"], target: "#story.story.travel", cond: "isNextScene" },
                        { target: "#story.story.travel", cond: "is360VideoPlaying" },
                        { actions: ["incrementChapter"], target: "#story.chapterIntro", cond: "isNextStory" },
                        { target: "#story.end" }
                      ]
                    },
                    always: {
                      target: "tutorial", cond: "shouldShowXRTutorial"
                    },
                  },
                  mediaopening: {
                    invoke: [
                      {
                        src: "chooseHotspot",
                        onDone: {
                          target: "media"
                        },
                      },
                    ]
                  },
                  media: {
                    initial: "default",
                    on: {
                      CLOSE_MEDIA: "default"
                    },

                    always: [
                      { target: ".xrslider", cond: "isXRSlider" },
                      { target: ".xrmap", cond: "isXRMap" },
                    ],
                    states: {
                      default: {
                        on: {
                          CLOSE_MEDIA: "mediaclosing"
                        },
                      },
                      mediaclosing: {
                        after: {
                          MEDIA_CLOSING_DURATION: "#story.#story.story.scene.default"
                        }
                      },
                      xrslider: {
                        entry: ["startSlider", "startSliderText"],
                        exit: ["stopSliderText"],

                        on: {
                          CLOSE_MEDIA: "xrsliderclosing"
                        },
                      },
                      xrsliderclosing: {
                        invoke: [
                          {
                            src: "closeSlider",
                            onDone: {
                              target: "#story.#story.story.scene.default"
                            },
                          },
                        ]
                      },
                      xrmap: {
                        entry: ["startMap"],
                        exit: ["stopMap"]
                      }
                    }
                  },
                  tutorial: {
                    entry: ["disableTuto", "startTutorial"],
                    exit: ["stopTutorial"],
                    on: {
                      CLOSE_TUTO: "default"
                    }
                  },
                  changeScene: {
                    always: "#story.chapterIntro"
                  }
                }
              },
              ditWait: {
                on: {
                  BACK_TO_STORY: {
                    actions: ["startCurrentScene"],
                    target: "scene"
                  }
                }
              }
            }
          },
          end: {
            entry: ["storyEnded"]
          }
        }
      }, {

      services: {

        async closeSlider() {
          await AppService.glapp.xrmodule.ui.stopSlider();
        },

        async chooseHotspot(ctx, evt) {
          const id = (evt as StoryMediaOpen).id;
          ctx.currHotspotId = id;
          await Delay(10)
        },
      },

      actions: {
        checkJump(ctx) {
          const progress: number = localStorageGet(LS_VAR) || 0;
          // const progress: number = 0;
          if (progress) {
            ctx.maxprogress = progress;
            ctx.progress = 0;
            const { chapter } = transformProgress(ctx.progress);
            ctx.chapter = chapter as ChapterIndex;
            ctx.scene = 0;
            // ctx.scene = scene;
          }
///////////////////////
///////////////////////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////
///////////////////////////////////////////////////
/////////////
///////////
////////////////////
        },
        storyEnded: pure((ctx) => {
          const prevAct: ActivityId = "chapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(prevAct)();
          AudioManager.fadeOutAndStop(AUDIO_ID.CHAPTER_4_MUSIC, AudioManager.musicInstance, 2500).then(() => {
            AudioManager.fadeIn(AUDIO_ID.MENU_MUSIC, 1, 2500);
          })
          ctx.sceneprogress = 0;
          // ctx.maxprogress = 0;
          ctx.progress = 0;
          ctx.chapter = ctx.scene = 0;
          ctx.video360Played = false;
          // localStorageSet(LS_VAR, 0);
          return sendParent("END_STORY");
        }),
        openDIT: pure((ctx) => {
          const prevAct: ActivityId = "chapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(prevAct)();
          return sendParent("OPEN_DIT");
        }),
        incrementScene: (ctx) => {
          ctx.scene = ctx.scene + 1;
          ctx.sceneprogress = 0;
        },
        incrementChapter: (ctx) => {
          ctx.scene = 0;
          const prevAct: ActivityId = "chapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(prevAct)();
          ctx.sceneprogress = 0;
          ctx.chapter++;
        },
        startCurrentScene: (ctx) => {
          const currAct: ActivityId = "chapter" + (ctx.chapter + 1) as ActivityId;
          startActivity(currAct)();
        },
        startChapterIntro: (ctx) => {
          // if (ctx.chapter > 0) stopActivity("introChapter1")();
          if (ctx.chapter === 3) ctx.video360Played = false;
          const currAct: ActivityId = "introChapter" + (ctx.chapter + 1) as ActivityId;
          if (AppService.isXr()) {
            AppService.glapp.xrmodule.ui.startChapter(ctx.chapter);
          }
          if (checkIsActive(currAct)) return;
          startActivity(currAct)();
        },
        stopChapterIntro: (ctx) => {
          const currAct: ActivityId = "introChapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(currAct)();
        },
        startChapterStoryIntro: (ctx) => {
          localStorageSet(LS_VAR, ctx.maxprogress);
          const currAct: ActivityId = "introStoryChapter" + (ctx.chapter + 1) as ActivityId;
          if (checkIsActive(currAct)) return;
          startActivity(currAct)();
        },
        stopChapterStoryIntro: (ctx) => {
          const currAct: ActivityId = "introStoryChapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(currAct)();
        },
        setVideo360Played: assign({ video360Played: true }),
        disableTuto: assign({ showXRTutorial: false }),
        openMenu: assign({ isMenuOpen: true }),
        closeMenu: assign({ isMenuOpen: false }),
        startTutorial: () => AppService.glapp.xrmodule.ui.startTutorial(),
        stopTutorial: () => AppService.glapp.xrmodule.ui.stopTutorial(),
        startSliderText: () => AppService.glapp.xrmodule.ui.startSliderText(),
        stopSliderText: () => AppService.glapp.xrmodule.ui.stopSliderText(),
        startSlider: () => AppService.glapp.xrmodule.ui.startSlider(),
        startMap: () => AppService.glapp.xrmodule.ui.startMap(),
        stopMap: () => AppService.glapp.xrmodule.ui.stopMap(),
        startXrSubs: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.startSubtitlesRender(),
        stopXrSubs: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.stopSubtitlesRender(),
        // startChapterUI: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.startChapterUI(),
        stopChapterUI: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.stopChapterUI(),
        setProgress: (ctx, evt) => {

          const sceneProgress = (evt as StoryProgress).progress || 0;
          const progress = calculateProgress(ctx.chapter, ctx.scene, sceneProgress);
          ctx.progress = progress;
          ctx.sceneprogress = sceneProgress;
          ctx.maxprogress = Math.max(ctx.progress, ctx.maxprogress);
        },
        changeScene: (ctx, evt) => {
          const prevAct: ActivityId = "chapter" + (ctx.chapter + 1) as ActivityId;
          stopActivity(prevAct)();
          const { chapter, scene } = evt as StoryGoTo;
          ctx.chapter = chapter as ChapterIndex;
          ctx.scene = scene;
          ctx.sceneprogress = 0;
        },
        initCanGoNext: (ctx) => {
          const progress = Math.round((calculateProgress(ctx.chapter, ctx.scene, 0) + Number.EPSILON) * 10000) / 10000;

          const ratioChapter = 1 / content.chapters.length;
          const ratioScene = 1 / content.chapters[ctx.chapter].scenes.length;
          const percScene = ratioChapter * ratioScene * ctx.sceneprogress;
          const roundmax = Math.round((ctx.maxprogress - percScene) * 10000) / 10000;
          ctx.canGoNext = roundmax > progress;
        },
        enableNext: assign({ canGoNext: true }),
        video360Authorized: assign({ video360Authorized: true }),
      },
      guards: {
        isNextStory(ctx) {
          return ctx.chapter < content.chapters.length - 1;
        },

        isNextScene(ctx) {
          return ctx.scene < content.chapters[ctx.chapter].scenes.length - 1;
        },
        is360VideoPlaying(ctx) {
          return ctx.chapter === 3 && !ctx.video360Played;
        },
        isFirstChapter(ctx) {
          return ctx.chapter === 0;
        },
/////////////////////
////////////////////////////
///////////////////////////////////////////////////////////////////
//////////
///////////////////////////
//////////////////////////////////
//////////
//////////////////
        shouldShowXRTutorial(ctx) {
          const isXR = (AppService.isXr() as boolean);
          return ctx.showXRTutorial && isXR;
        },
        isXRSlider(ctx, evt, meta) {
          const isSlider = meta.state.matches("story.scene.media.xrslider") || meta.state.matches("story.scene.media.xrsliderclosing")
          const isSliderContent = getSceneHotspotType(ctx.chapter, ctx.scene, ctx.currHotspotId) === HotSpotMediaType.MEDIA
          const isXR = (AppService.isXr() as boolean);
          return isXR && !isSlider && isSliderContent;
        },
        isXRMap(ctx, evt, meta) {
          const isMap = meta.state.matches("story.scene.media.xrmap")
          const isXR = (AppService.isXr() as boolean);
          const isMapContent = getSceneHotspotType(ctx.chapter, ctx.scene, ctx.currHotspotId) === HotSpotMediaType.MAP || getSceneHotspotType(ctx.chapter, ctx.scene, ctx.currHotspotId) === HotSpotMediaType.MAP_EUROPE || getSceneHotspotType(ctx.chapter, ctx.scene, ctx.currHotspotId) === HotSpotMediaType.MAP_1
          return isXR && !isMap && isMapContent;
        },
        notIsMobile: () => {
          return !Viewport.isMobile;
        },
        isVideo360Mobile: (ctx) => {
          return ctx.chapter === 3 && Viewport.isMobile && !ctx.video360Authorized;
        }
      },
      delays: {
        MEDIA_CLOSING_DURATION,
        INTRO_READING_TIMING: (ctx) => {
          const lang = getLocale();
          const timing = getReadingTiming(ctx.chapter, lang);
          return timing * 1000;
        },
        INTRO_VIDEO_TIMING: (ctx) => {
          const lang = getLocale();
          const timing = getVideoTiming(ctx.chapter, lang);
          return timing * 1000;
        }
      }
    }
    );
};


export type StoryStateMachineType = typeof StoryStateMachine


export default StoryStateMachine;