import gsap from "gsap";

/**
 * Change transformOrigin without a jump
 * @see https://gsap.com/docs/v3/HelperFunctions/helpers/smoothOriginChange
 * @param targets
 * @param transformOrigin
 */
export function smoothOriginChange(
  targets: gsap.TweenTarget,
  transformOrigin: string
) {
  gsap.utils.toArray<Element>(targets).forEach(function (target) {
    const before = target.getBoundingClientRect();
    gsap.set(target, { transformOrigin });
    const after = target.getBoundingClientRect();
    gsap.set(target, {
      x: `+=${before.x - after.x}`,
      y: `+=${before.y - after.y}`,
    });
  });
}
