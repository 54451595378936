type TimeUnit = "s" | "second" | "ms" | "millisecond";

/**
 * Convert number of frames duration to time
 * @param {Number} frame Number of frame
 * @param {Number} fps Framerate. Default to 60
 * @param {String} output Output time unit. Default to 'second'
 * @returns {Number}
 */
const frameToTime = (
  frame: number,
  fps: number = 60,
  output: TimeUnit = "second"
): number => {
  let duration = 0;
  switch (output) {
    case "s":
    case "second":
      duration = frame / fps;
      break;

    case "ms":
    case "millisecond":
      duration = (frame / fps) * 1000;
      break;
  }

  return duration;
};

// curry method to set default framerate
const setFPS =
  (defaultFPS: number) =>
  (
    frame: number,
    fps: number = defaultFPS,
    output: TimeUnit = "second"
  ): number =>
    frameToTime(frame, fps, output);

export default frameToTime;

export { frameToTime, setFPS };
