import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import Signal from './Signal';

let audioConfig: SpeechSDK.AudioConfig, speechConfig: SpeechSDK.SpeechConfig;

async function getLocalStream() {
  const stream = await navigator.mediaDevices
    .getUserMedia({ video: false, audio: true })

    .catch((err) => {
      console.error(`you got an error: ${err}`);
      return false;
    });

  if (stream) return true;
}

interface RecognizerOptions {
  language?: string;
}

async function RecognizerSetup(serviceRegion: string, subscriptionKey: string, { language = 'en-US' }: RecognizerOptions = {}) {
  const isStream = await getLocalStream();
  if (!isStream) {
    console.error('no stream');
    return null;
  }
  speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
  speechConfig.speechRecognitionLanguage = language;
  // 1 here is for a JSON output. 
  speechConfig.outputFormat = 1;
  speechConfig.setProfanity(SpeechSDK.ProfanityOption.Raw);
  audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  return new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
}

let recognizer: SpeechSDK.SpeechRecognizer | null = null;

async function start(cb: Signal<string>, options: RecognizerOptions = {}) {
  if (!recognizer || recognizer === null) recognizer = await RecognizerSetup(process.env.VUE_APP_AZURE_REGION, process.env.VUE_APP_AZURE_SUBSCRIPTION_KEY, options);
  if (recognizer === null) return;
  // recognizer.startContinuousRecognitionAsync();
  recognizer.recognizeOnceAsync(
    function(result) {
      if ("undefined" !== typeof result.json && result.json !== "") {
        cb.emit(result.json);
      }
    });
}

function stop() {
  // recognizer.stopContinuousRecognitionAsync();
}




export default recognizer;

export {
  getLocalStream,
  start,
  stop,
}


