import AudioManager from "@webgl/core/audio/AudioManager";
import Viewport from "@/store/modules/Viewport";
import type { Directive } from "vue";

interface SoundDirectiveElement extends Element {
  __soundHoverHandler?: EventListener;
  __soundClickHandler?: EventListener;
}

interface SoundDirectiveOptions {
  hover?: string;
  click?: string;
}

/**
 * Custom directive to trigger UI sounds on hover and/or click.
 * - Add `v-sound.hover`, `v-sound.click` or both `v-sound.hover.click` to any element to trigger UI sounds.
 * - Add option as value to specifiy sound name, e.g. `v-sound.click="{ click: 'UI_SECONDARY_CLICK' }"`.
 * - Sound can be conditionally disabled with `false` as value, e.g. `v-sound="false"`.
 */
const sound: Directive<SoundDirectiveElement, SoundDirectiveOptions | boolean> =
  {
    mounted: (el, { value, modifiers }) => {
      const disabled = typeof value === "boolean" && !value;
      if (disabled) {
        el.removeEventListener("mouseenter", el.__soundHoverHandler);
        el.removeEventListener("click", el.__soundClickHandler);
        return;
      }

      const soundNames = { hover: "UI_HOVER", click: "UI_CLICK" };
      if (!!value && typeof value !== "boolean") {
        if (typeof value === "string") {
          soundNames.click = value || soundNames.click;
        } else {
          soundNames.hover = value.hover || soundNames.hover;
          soundNames.click = value.click || soundNames.click;
        }
      }

      const { hover, click } = modifiers;
      if (!Viewport.isTouch && hover) {
        el.__soundHoverHandler = () =>
          AudioManager.playUI(soundNames.hover, 0, 1.0, true);
        el.addEventListener("mouseenter", el.__soundHoverHandler);
      }
      if (click) {
        el.__soundClickHandler = () => AudioManager.playUI(soundNames.click);
        el.addEventListener("click", el.__soundClickHandler, { capture: true });
      }
    },
    unmounted: (el) => {
      el.removeEventListener("mouseenter", el.__soundHoverHandler);
      el.removeEventListener("click", el.__soundClickHandler);
    },
  };

export default sound;
