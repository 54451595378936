import { onMounted, ref, toValue, watch, type MaybeRefOrGetter } from "vue";

export default function useLocalTransition(
  externalState: MaybeRefOrGetter<boolean>
) {
  // local ref to prevent double enter transition after build
  const show = ref(false);

  watch(
    () => toValue(externalState),
    (isVisible) => {
      show.value = isVisible;
    }
  );

  onMounted(() => {
    requestAnimationFrame(() => {
      show.value = toValue(externalState);
    });
  });

  return show;
}
