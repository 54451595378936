import { ActorRefFrom, assign, createMachine, interpret, pure, spawn } from "xstate";

import Delay from "@/core/Delay";
import { AppEvent } from "./AppEvents";
import AppService from "../AppService";
import { sendTo } from "xstate/lib/actions";
import WebXRView from "@webgl/xr/WebXRView";
import { get as lsget, set as lsset } from "local-storage";
import AudioManager from "@webgl/core/audio/AudioManager";
import { LAZY_LOAD_ANIM_DELAY, PRELOADER_MIN_DURATION } from "@/config/constants";
import { ActivityId } from "@webgl/activities/ActivityRegistry";
import XRStateMachine, { XRStateMachineType } from "./XRStateMachine";
import StoryStateMachine, { LS_VAR, StoryStateMachineType, transformProgress } from "./StoryStateMachine";
import Viewport from "@/store/modules/Viewport";
import { LANDING_IDLE_DELAY } from "@/config/constants";

export function localStorageGet(key: string): number {
  if (Viewport.hasLocalStorage) return lsget(key);
}
export function localStorageSet(key: string, value: number) {
  if (Viewport.hasLocalStorage) return lsset(key, value);
}

type AppContext = {
  loaded: boolean,
  lazyloaded: boolean,
  childSMCreated: boolean,
  isXrSupported: boolean,
  isXR: boolean,
  storyEnded: boolean,
  _xr: ActorRefFrom<XRStateMachineType> | null,
  _story: ActorRefFrom<StoryStateMachineType> | null
}

function CreateContext(): AppContext {
  return {
    //First assets loaded
    loaded: false,
    // xr state machine
    _xr: null,
    // story state machine
    _story: null,
    // check when the xr and story state machine are created
    childSMCreated: false,
    // start loaded
    lazyloaded: false,
    // is xr supported on the device
    isXrSupported: false,
    // is xr active
    isXR: false,
    // has story ended
    storyEnded: false
  };
}

export type AppStateContext = ReturnType<typeof CreateContext>;

export type AppStateType = {
  value: string;
  context: AppStateContext;
};

const activities: ActivityId[] = ["home", "introChapter1", "introChapter2", "introChapter3", "introChapter4", "introStoryChapter1", "introStoryChapter2", "introStoryChapter3", "introStoryChapter4", "chapter1", "chapter2", "chapter3", "chapter4", "dit"];

const activitiesPriorityLoading: ActivityId[] = ["home", "dit", "introStoryChapter1", "introChapter1", "chapter1", "introChapter2", "introStoryChapter2", "chapter2"];

async function createActivities(id: ActivityId[]) {
  await Promise.all(id.map((id) => AppService.Scene.activities.createActivity(id)));
}

/////////////
//////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////
 
////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////
 
//////////

export function loadActivity(id: ActivityId) {
  return function(): Promise<unknown> {
    return AppService.Scene.activities.loadActivity(id);
  };
}

export function startActivity(id: ActivityId) {
  return function() {
    // console.log("start activity =>", id);
    AppService.Scene.activities.startActivity(id);
  };
}

export function startIntroActivity() {
  return function() {
    const progress = 0;//(localStorageGet(LS_VAR) || 0) as number;
    // const progress = 0;
    const { chapter } = transformProgress(progress);
    AppService.Scene.activities.startActivity("introChapter" + (chapter + 1) as ActivityId);
  };
}

export function stopActivity(id: ActivityId) {
  return function() {
    // console.log("stop activity =>", id);
    AppService.Scene.activities.stopActivity(id);
  };
}

function CreateAppStateMachine() {
  return createMachine<AppStateContext, AppEvent, AppStateType>(
    {

      id: "app",

      initial: "initial",

      context: CreateContext(),

      states: {
        // STATE INIT
        initial: {
          on: {
            INIT: {
              target: "create_activities"
            },
          },
        },
        create_activities: {
          invoke: [
            {
              src: "createActivities",
              onDone: {
                target: "loading"
              },
            },
          ],
        },
        loading: {
          entry: ["spawnStory", "spawnXR", "setChildSMCreated"],
          invoke: [
            {
              src: "load",
              onDone: {
                target: "loaded"
              },
            },
          ]
        },
        loaded: {
///////////////////////
///////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////
//////////////////
///////////////////////////////////////////////////////////
////////////
////////////////////
          on: {
            NEXT: "home"
          }
        },
        home: {
          initial: "landing",
          states: {
            landing: {
              initial: "entering",
              states: {
                entering: {
                  on: {
                    NEXT: "idle",
                  },
                },
                idle: {
                  after: {
                    LANDING_IDLE_DELAY: "#app.home.home"
                  },
                },
              },
            },
            home: {
              initial: "page",
              states: {
                page: {
                  initial: "default",
                  entry: [startActivity("home")],
                  exit: [stopActivity("home")],
                  on: {
                    GO_TO_ABOUT: "about",
                    GO_TO_END: "#app.end"
                  },
                  states: {
                    default: {
                      on: {
                        OPEN_DIT: "#app.dit",
                        NEXT: "enterExperience",
                      },
                      invoke: [
                        { src: "lazyload" },
                      ],
                    },
                    enterExperience: {
                      on: {
                        OPEN_DIT: "#app.dit",
                        NEXT: [
                          {
                            target: "#app.home.home.page.requestXR",
                            cond: "xrSupported"
                          },
                          {
                            target: "story"
                          }
                        ],
                      }
                    },
                    requestXR: {
                      entry: ["tryXR"],
                      on: {
                        XR_RESPONSE: {
                          actions: ["setIsXR"],
                          target: "#app.home.home.page.story",
                          internal: true
                        },
                      }
                    },
                    story: {
                      initial: "intro",
                      states: {
                        intro: {
                          on: {
                            NEXT: "reading"
                          }
                        },
                        reading: {
                          on: {
                            SKIP: "#app.home.home.page.charlotteVideo",
                          },
                          after: {
/////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
                            HOME_STORY_READING_TIMING: { target: "#app.home.home.page.charlotteVideo", cond: "notIsMobile" },
                          }
                        },
                      }
                    },
                    charlotteVideo: {
                      entry: ["startXrSubs"],
                      exit: ["stopXrSubs"],
                      after: {
/////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////
                        HOME_CHARLOTTE_VIDEO_TIMING: "#app.home.home.page.exitStory"
                      }
                    },
                    exitStory: {
                      entry: [startIntroActivity()],
                      after: {
/////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////////////
                        HOME_EXIT_DURATION: "#app.story"
                      }
                    }
                  }
                },
                about: {
                  on: {
                    BACK: "page"
                  }
                }
              }
            },
          }
        },
        story: {
          entry: ["startStory"],
          on: {
            END_STORY: {
              actions: ["storyEnded"],
              target: "dit"
            },
            OPEN_DIT: "dit"
          }
        },
        dit: {
          entry: [startActivity("dit"), "startDitAQ", "startXrSubs"],
          exit: [stopActivity("dit"), "stopDitAQ", "stopXrSubs"],
          on: {
            BACK: [
              { target: "story", cond: (_ctx, e) => e.to === "story" },
              { target: "home.home" },
            ],
            NEXT: "end"
          },
          initial: "default",
          states: {
            default: {
              on: {
                DIT_GO_TO_STT: "speechToText"
              }
            },
            speechToText: {
              on: {
                DIT_END_STT: "default"
              }
            }
          }
        },
        end: {
          entry: ["endXR"],
          on: {
            BACK: {
              target: "home.home"
            },
            XR_RESPONSE: {
              target: "home.home.page",
              internal: true
            },
          }
        }
      },
    },
    {
      actions: {
        spawnXR: assign<AppStateContext, AppEvent>({
          _xr: () => spawn(XRStateMachine, "xr")
        }),
        spawnStory: assign<AppStateContext, AppEvent>({
          _story: () => {
            const s = spawn(StoryStateMachine(), {
              name: "story",
              autoForward: true
            });
            return s;
          }

        }),
        setChildSMCreated: (ctx) => {
          ctx.childSMCreated = true;
        },
        endXR: pure(ctx => {
          if (ctx.isXR) {
            ctx.isXR = false;
            return sendTo("xr", { type: "SESSION_END" });
          }
        }),
        tryXR: pure(ctx => {
          if (!process.env.VUE_APP_DISABLE_XR || process.env.VUE_APP_DISABLE_XR !== "true" && ctx.isXrSupported)
            return sendTo("xr", { type: "REQUEST" });
          else if (ctx.isXrSupported) console.warn("XR disabled");
        }),
        startStory: pure(ctx => {
          const storyState = ctx._story.getSnapshot();
          return sendTo("story", { type: storyState.value === "initial" || storyState.value === "end" ? "INIT_STORY" : "BACK_TO_STORY" });
        }),
        storyEnded: assign({ storyEnded: true }),
        setIsXR: ctx => {
          AppService.glapp.renderer.scene.celShading.removeNoise();
          ctx.isXR = true;
        },
        startDitAQ: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.startDitAskQuestion(),
        stopDitAQ: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.stopDitAskQuestion(),
        startXrSubs: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.startSubtitlesRender(),
        stopXrSubs: () => AppService.glapp.xrmodule?.Ready && AppService.glapp.xrmodule.ui.stopSubtitlesRender(),
      },
      services: {
        async createActivities() {
          await createActivities(activities);
        },
        // Here we load all things that needs to be loaded during the loader
        async load(ctx) {
          if (ctx.loaded) return Promise.resolve();
          // const storyProgress = 0;
          // console.log("loading chapter", chapter + 1, storyProgress)
          const toLoad: Promise<unknown>[] = [];
          await AppService.Scene.load();
          await AudioManager.earlyLoad();
          const xrSupported = await WebXRView.IsSessionSupportedAsync("immersive-vr");
          ctx.isXrSupported = xrSupported && !Viewport.isMobile;
          // console.log("Priority loading => ", activitiesPriorityLoading)
///////////////////////
////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////
          toLoad.push(...activitiesPriorityLoading.map((id) => loadActivity(id)()));
////////////////////
          toLoad.push(AppService.glapp.load(ctx.isXrSupported));
          // wait for a minimum amount of time to play Preloader animations
///////////////////////
////////////////////////////////////////////////////////
//////////////////////
            toLoad.push(Delay(PRELOADER_MIN_DURATION));
          await Promise.all(toLoad);
          ctx.loaded = true;
          return Promise.resolve();
        },
        // Here we load everything else
        async lazyload(ctx) {
          if (ctx.lazyloaded) return Promise.resolve();
          let toLoad: Promise<unknown>[] = [];
          // if (Viewport.isDesktop)
          await Delay(LAZY_LOAD_ANIM_DELAY);
          toLoad.push(...activities.filter(a => !activitiesPriorityLoading.includes(a)).map((id) => loadActivity(id)()));
///////////////////////
////////////////////////////
////////////////////////
///////////
////////////////////
          await Promise.all(toLoad);
          ctx.lazyloaded = true;

          // console.log("done !!!! all loaded")
          return Promise.resolve();
        }
      },
      guards: {
/////////////////////
////////////////////////
/////////////////////////////
//////////
////////////////////////////
///////////////////////////////////////////////////////////
//////////
//////////////////
        xrSupported: (ctx) => {
          return (ctx.isXrSupported);
        },
        notIsMobile: () => {
          return !Viewport.isMobile;
        }
      },
      delays: {
        LANDING_IDLE_DELAY,
        HOME_STORY_READING_TIMING: 19000,
        HOME_CHARLOTTE_VIDEO_TIMING: 10400, // 2000  + 10390
        HOME_EXIT_DURATION: 6000
      }
    }
  );
}

export function CreateAppStateInterpreter() {
  const res = interpret(CreateAppStateMachine());

  res.onTransition((state, evt) => {
    let currentState = state.value;
    if (typeof currentState === "object") {
      const keys = Object.keys(state.value);
      if (keys.length > 0) {
        currentState = keys[0];
      }
    }

    document.body.className = currentState.toString();
  });

  return res;
}

export type AppStateInterpreter = ReturnType<typeof CreateAppStateInterpreter>;
