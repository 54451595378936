import { reactive } from "vue";

export enum CursorState {
  DEFAULT,
  HOVER,
  DRAG,
  DRAGGING,
}

interface Cursor {
  state: CursorState;
  visible: boolean;
}

const Cursor = reactive<Cursor>({
  state: CursorState.DEFAULT,
  visible: false,
});

function setCursor(state: CursorState = CursorState.DEFAULT) {
  Cursor.state = state;
}

function setCursorHover(isHover: boolean) {
  setCursor(isHover ? CursorState.HOVER : CursorState.DEFAULT);
}

function setCursorVisible(visible: boolean) {
  Cursor.visible = visible;
}

export { Cursor as default, setCursor, setCursorHover, setCursorVisible };
