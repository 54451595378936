import GltfNode from "nanogl-gltf/lib/elements/Node";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import { vec3, vec4 } from "gl-matrix";

import Ray from "@webgl/math/Ray";
import lerp from "@/utils/Lerp";
import AppService from "@/services/AppService";
import TpButtonChunk from "@webgl/glsl/tp_button/TpButtonChunk";
import rayPointDistance from "@webgl/math/rayPointDistance";
import { IRaycastable } from "@webgl/core/Raycastable";
import { RenderContext } from "@webgl/core/Renderer";
import AudioManager from "@webgl/core/audio/AudioManager";

class TpButtonRaycastable implements IRaycastable {
  private _isHover = false;
  private _castPos: vec4;

  constructor(
    private node: GltfNode,
    private _handleHover: (val: boolean) => void,
    private _handleClick: () => void
  ) {
    this._castPos = vec4.create();
  }

  castPos(): vec4 {
    return this._castPos;
  }

  isHover(): boolean {
    return this._isHover;
  }

  private doRaycast(ray: Ray) {
    const pos = this.node._wposition;
    const dist = rayPointDistance(ray, pos)

    if (dist >= this.node.scale[0]) return false;

    this._castPos.set([
      pos[0],
      pos[1],
      pos[2],
      vec3.distance(ray.origin, pos)
    ]);

    return true;
  }

  raycast(ray: Ray): void {
    const hasHit = this.doRaycast(ray);
    this.toggleHover(hasHit);
  }

  raycastList(rays: Ray[]): number {
    let hasHit = false;
    let idx = 0;

    for (let i = 0; i < rays.length; i++) {
      const hit = this.doRaycast(rays[i]);
      if (hit) {
        hasHit = true;
        idx = i;
      }
    }

    this.toggleHover(hasHit);

    return idx;
  }

  toggleHover(val: boolean): void {
    const wasHover = this._isHover;
    this._isHover = val;

    if (wasHover === this._isHover) return;
    if (this._isHover) AudioManager.playUI("UI_HOVER", 0, 1.0, true);

    this._handleHover(val);
  }

  click(): void {
    this._handleClick();
  }
}

export default class TpButton {

  static hoverValue = 0;

  chunk: TpButtonChunk;
  hoverAmount = 0;
  raycastable: TpButtonRaycastable;


  constructor(
    public node: GltfNode, public renderable: MeshRenderer
  ) {
    const pass = renderable.materials[0]?.getPass("color").pass;
    this.chunk = pass.inputs._chunks.find((c: any) => c instanceof TpButtonChunk) as TpButtonChunk;
  }

  // RAYCASTABLE

  createRaycastable() {
    this.raycastable = new TpButtonRaycastable(this.node, this.hover, this.click);
  }

  // INTERACTIONS

  hover = (val: boolean): void => {
    if (val) {
      AppService.glapp.xrmodule.tooltips.push("Right/Trigger", "xr.teleport", true);
    } else {
      AppService.glapp.xrmodule.tooltips.pop("Right/Trigger", "xr.teleport");
    }
  };

  stop() {
    if (AppService.isXr()) AppService.glapp.xrmodule.tooltips.pop("Right/Trigger", "xr.teleport");
  }

  click = (): void => {
    this.raycastable.toggleHover(false);
    AppService.getStoryState().send("NEXT_SCENE");
  };

  // RENDER

  preRender() {
    const val = AppService.isXr() ? (this.raycastable?.isHover() ? 1 : 0) : TpButton.hoverValue;
    this.hoverAmount = lerp(this.hoverAmount, val, 0.1);
  }

  render(ctx: RenderContext, visibility: number = 1) {
    this.chunk?.hoverUniform.set(this.hoverAmount);
    this.chunk?.visibilityUniform.set(visibility);

    this.renderable.render(ctx.gl, ctx.camera, ctx.mask, ctx.pass, ctx.glConfig);
  }
}