import WebXRInputs from "@/webgl/xr/WebXRInputs";

export default class KeyboardBinding {

  axes: Map<XRHandedness, Float32Array>

  _axisDownCount = 0;

  get shouldOverrideAxes(): boolean {
    return this._axisDownCount != 0;
  }

  constructor(
    public readonly inputs: WebXRInputs
  ) {

    this.axes = new Map<XRHandedness, Float32Array>();
    this.axes.set("left", new Float32Array(2));
    this.axes.set("right", new Float32Array(2));

  }

  attach() {
    document.addEventListener("keydown", this.keydown);
    document.addEventListener("keyup", this.keyup);
  }

  detach() {
    document.removeEventListener("keydown", this.keydown);
    document.removeEventListener("keyup", this.keyup);
  }

  keydown = (e: KeyboardEvent) => {

    switch (e.key) {
      case "ArrowDown":
        this._axisDownCount += 1;
        this.axes.get("left")[1] = 1;
        this.inputs.onBtnDown.emit({
          name: "Left/Down",
          button: null
        });
        break;
      case "ArrowLeft":
        this._axisDownCount += 1;
        this.axes.get("left")[0] = -1;
        this.inputs.onBtnDown.emit({
          name: "Left/Left",
          button: null
        });
        break;
      case "ArrowUp":
        this._axisDownCount += 1;
        this.axes.get("left")[1] = -1;
        this.inputs.onBtnDown.emit({
          name: "Left/Up",
          button: null
        });
        break;
      case "ArrowRight":
        this._axisDownCount += 1;
        this.axes.get("left")[0] = 1;
        this.inputs.onBtnDown.emit({
          name: "Left/Right",
          button: null
        });
        break;
      case "5":
        this._axisDownCount += 1;
        this.axes.get("right")[1] = 1;
        this.inputs.onBtnDown.emit({
          name: "Right/Down",
          button: null
        });
        break;
      case "4":
        this._axisDownCount += 1;
        this.axes.get("right")[0] = 1;
        this.inputs.onBtnDown.emit({
          name: "Right/Left",
          button: null
        });
        break;
      case "8":
        this._axisDownCount += 1;
        this.axes.get("right")[1] = -1;
        this.inputs.onBtnDown.emit({
          name: "Right/Up",
          button: null
        });
        break;
      case "6":
        this._axisDownCount += 1;
        this.axes.get("right")[0] = -1;
        this.inputs.onBtnDown.emit({
          name: "Right/Right",
          button: null
        });
        break;
      case "a":
        this.inputs.onBtnDown.emit({
          name: "A",
          button: null
        });
        break;
      case "b":
        this.inputs.onBtnDown.emit({
          name: "B",
          button: null
        });
        break;
      case "x":
        this.inputs.onBtnDown.emit({
          name: "X",
          button: null
        });
        break;
      case "y":
        this.inputs.onBtnDown.emit({
          name: "Y",
          button: null
        });
        break;

      // LEFT TRIGGERS /
      case "q":
        this.inputs.onBtnDown.emit({
          name: "Left/Squeeze",
          button: null
        });
        break;
      case "z":
        this.inputs.onBtnDown.emit({
          name: "Left/Trigger",
          button: null
        });
        break;
      case "d":
        this.inputs.onBtnDown.emit({
          name: "Left/Thumbstick",
          button: null
        });
        break;

      // RIGHT TRIGGERS /
      case "i":
        this.inputs.onBtnDown.emit({
          name: "Right/Trigger",
          button: null
        });
        break;
      case "l":
        this.inputs.onBtnDown.emit({
          name: "Right/Thumbstick",
          button: null
        });
        break;
      case "j":
        this.inputs.onBtnDown.emit({
          name: "Right/Squeeze",
          button: null
        });
        break;
      default:
        break;
    }
  }

  keyup = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        this._axisDownCount -= 1;
        this.axes.get("left")[1] = 0;
        this.inputs.onBtnUp.emit({
          name: "Left/Down",
          button: null
        });
        break;
      case "ArrowLeft":
        this._axisDownCount -= 1;
        this.axes.get("left")[0] = 0;
        this.inputs.onBtnUp.emit({
          name: "Left/Left",
          button: null
        });
        break;
      case "ArrowUp":
        this._axisDownCount -= 1;
        this.axes.get("left")[1] = 0;
        this.inputs.onBtnUp.emit({
          name: "Left/Up",
          button: null
        });
        break;
      case "ArrowRight":
        this._axisDownCount -= 1;
        this.axes.get("left")[0] = 0;
        this.inputs.onBtnUp.emit({
          name: "Left/Right",
          button: null
        });
        break;
      case "5":
        this._axisDownCount -= 1;
        this.axes.get("right")[1] = 0;
        this.inputs.onBtnUp.emit({
          name: "Right/Down",
          button: null
        });
        break;
      case "4":
        this._axisDownCount -= 1;
        this.axes.get("right")[0] = 0;
        this.inputs.onBtnUp.emit({
          name: "Right/Left",
          button: null
        });
        break;
      case "8":
        this._axisDownCount -= 1;
        this.axes.get("right")[1] = 0;
        this.inputs.onBtnUp.emit({
          name: "Right/Up",
          button: null
        });
        break;
      case "6":
        this._axisDownCount -= 1;
        this.axes.get("right")[0] = 0;
        this.inputs.onBtnUp.emit({
          name: "Right/Right",
          button: null
        });
        break;
      case "a":
        this.inputs.onBtnUp.emit({
          name: "A",
          button: null
        });
        break;
      case "b":
        this.inputs.onBtnUp.emit({
          name: "B",
          button: null
        });
        break;
      case "x":
        this.inputs.onBtnUp.emit({
          name: "X",
          button: null
        });
        break;
      case "y":
        this.inputs.onBtnUp.emit({
          name: "Y",
          button: null
        });
        break;
      // LEFT TRIGGERS /
      case "q":
        this.inputs.onBtnUp.emit({
          name: "Left/Squeeze",
          button: null
        });
        break;
      case "z":
        this.inputs.onBtnUp.emit({
          name: "Left/Trigger",
          button: null
        });
        break;
      case "d":
        this.inputs.onBtnUp.emit({
          name: "Left/Thumbstick",
          button: null
        });
        break;

      // RIGHT TRIGGERS /
      case "i":
        this.inputs.onBtnUp.emit({
          name: "Right/Trigger",
          button: null
        });
        break;
      case "l":
        this.inputs.onBtnUp.emit({
          name: "Right/Thumbstick",
          button: null
        });
        break;
      case "j":
        this.inputs.onBtnUp.emit({
          name: "Right/Squeeze",
          button: null
        });
        break;
      default:
        break;
    }
  }

}