import WebXRGamepad, { ButtonEvent, XRGamepadButton } from "@/webgl/xr/WebXRGamepad";
import Signal from "@/core/Signal";
import KeyboardBinding from "@/webgl/xr/dev/KeyboardBinding";


interface XRInputSourcesChangeEvent extends Event {
  readonly session: XRSession;
  readonly added: XRInputSource[];
  readonly removed: XRInputSource[];
}

export class WebXRInput {

  pose: XRPose;
  source: XRInputSource;
  gamepad: WebXRGamepad;
  handedness: XRHandedness;
  readonly hasGamepad: boolean;

  constructor(inputSource: XRInputSource) {
    this.source = inputSource;
    this.handedness = this.source.handedness;
    this.hasGamepad = false;

    if (this.source.gamepad) {
      this.hasGamepad = true;
      this.gamepad = new WebXRGamepad(this.source.handedness, this.source.gamepad);
    }

  }

  update(frame: XRFrame, refSpace: XRReferenceSpace | XRBoundedReferenceSpace) {
    this.pose = frame.getPose(this.source.targetRaySpace, refSpace);
    if (this.hasGamepad)
      this.gamepad.update();
  }

}

export default class WebXRInputs {

  session: XRSession;
  list: WebXRInput[];

  onBtnDown: Signal<ButtonEvent>;
  onBtnUp: Signal<ButtonEvent>;

  keyboard: KeyboardBinding;

  constructor() {
    this.list = [];
    this.onBtnDown = new Signal<ButtonEvent>();
    this.onBtnUp = new Signal<ButtonEvent>();

    this.keyboard = new KeyboardBinding(this);
    this.keyboard.attach();

  }

  init(session: XRSession) {
    this.reset();
    this.session = session;
    this.session.addEventListener("inputsourceschange", this.onInput.bind(session));
  }

  reset(){
    for (const input of this.list) {
      if (input.gamepad) {
        input.gamepad.onBtnDown.release();
        input.gamepad.onBtnUp.release();
      }
    }
    this.list = [];
  }

  onInput = (evt: XRInputSourceChangeEvent) => {

    for (const inputSource of evt.removed) {
      const idx = this.list.findIndex((xrinput) => xrinput.source == inputSource);
      if (idx !== -1) {
        if (this.list[idx].gamepad) {
          this.list[idx].gamepad.onBtnDown.release();
          this.list[idx].gamepad.onBtnUp.release();
        }
        this.list.splice(idx, 1);
      }
    }

    for (const inputSource of evt.added) {
      const xrinput = new WebXRInput(inputSource);
      this.list.push(xrinput);
      if (xrinput.gamepad) {
        xrinput.gamepad.onBtnDown.on((evt) => this.onBtnDown.emit(evt));
        xrinput.gamepad.onBtnUp.on((evt) => this.onBtnUp.emit(evt));
      }
    }

  }

  getInput(handedness: XRHandedness): WebXRInput{
    return this.list.find((value) => value.handedness == handedness);
  }

  getButton(name: XRGamepadButton): GamepadButton {
    let btn = { pressed: false, touched: false, value: 0 };
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].hasGamepad) {
        btn = this.list[i].gamepad.getButton(name);
      }
    }
    return btn;
  }

  getAxes(handedness: XRHandedness) {
    let axes = [0, 0];
    const input = this.list.find((value) => value.handedness == handedness);
    if (!input)
      return axes;
    axes = input.hasGamepad ? input.gamepad.getAxes() : axes;
    if (this.keyboard.shouldOverrideAxes) {
      axes[0] = this.keyboard.axes.get(handedness)[0];
      axes[1] = this.keyboard.axes.get(handedness)[1];
    }
    return axes;
  }

  update(frame: XRFrame, refSpace: XRReferenceSpace | XRBoundedReferenceSpace) {
    for (const input of this.list) {
      input.update(frame, refSpace);
    }
  }

}