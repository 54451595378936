var fn = function( obj ){
var __t,__p='';
__p+='';
 if(obj.slot === 'pf' ){ 
__p+='\n\nIN vec2 vTexCoord;\n\n';
 } 
__p+='\n\n';
 if(obj.slot === 'postf' ){ 
__p+='\n\nfloat dist = distance(vTexCoord, vec2(0.5, 0.5));\n\n// BASE CIRCLE\n\nfloat circleSize = 0.4 * uVisibility();\nfloat borderCircleSize = 0.35 * uVisibility();\nfloat circleLimit = smoothstep(circleSize - 0.01, circleSize, dist);\nfloat borderCircle = smoothstep(borderCircleSize - 0.01, borderCircleSize, dist);\n\nfloat hoverCircleSize = mix(0., 0.35, uHover()) * uVisibility();\nfloat hoverCircle = smoothstep(hoverCircleSize + 0.01, hoverCircleSize, dist);\n\nfloat circleAlpha = mix(0.0, 1., borderCircle + hoverCircle);\nfloat circle = mix(circleAlpha, 0., circleLimit);\n\n// DOTTED OUTER CIRCLE\n\nfloat dots = 1. - min(mod(floor(atan(vTexCoord.y - 0.5, vTexCoord.x - 0.5) * 10.), 3.), 1.);\nfloat dottedCircleSize = mix(0.39, 0.5, max(uHover() * 3. - 2., 0.)) * uVisibility();\nfloat dottedCircle = (\n  smoothstep(dottedCircleSize - 0.03, dottedCircleSize - 0.02, dist) - smoothstep(dottedCircleSize - 0.01, dottedCircleSize, dist)\n) * dots;\n\n// ICON\n\nfloat icon = texture2D(uTexture, vTexCoord * 4. - 1.5).a * uVisibility();\n\n// OUTPUT\n\nFragColor.rgb = mix(uColorPrimary(), uColorSecondary(), uHover() * icon);\nFragColor.a = min(circle + dottedCircle + icon, 1.);\n\n';
 } 
__p+='';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
