import germany from "./germany.json";
import europe from "./europe.json";

type Dimensions = { width: number; height: number };
type Coordinates = { x: number; y: number };

export type MapId = "germany" | "europe";

export type MapHotspot = {
  id: string;
  coordinates: Coordinates;
  placement?: string;
  areaId?: string; // if hotspot shares the same area as another one
};

interface MapConfig {
  dimensions: Dimensions;
  center?: Partial<Coordinates>;
  hotspots: MapHotspot[];
}

const maps: Record<MapId, MapConfig> = {
  germany,
  europe,
};

function getMapConfig(id: MapId) {
  return maps[id];
}

export { getMapConfig };
