/**
 * Round a number to a given number of digits
 * @see https://phuoc.ng/collection/1-loc/round-a-number-to-a-given-number-of-digits/
 * @param {number} n
 * @param {number} decimals
 * @returns
 */
export const round = (n: number, decimals: number = 0): number =>
  Number(`${Math.round(`${n}e${decimals}` as unknown as number)}e-${decimals}`);

/**
 * Clamp a number between two values
 * @see https://phuoc.ng/collection/1-loc/clamp-a-number-between-two-values/
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @returns
 */
export const clamp = (val: number, min: number = 0, max: number = 1): number =>
  Math.max(min, Math.min(max, val));
