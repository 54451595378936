export enum HotSpotMediaType {
  MEDIA = 0,
  MAP = 1,
  MAP_1 = 2,
  MAP_EUROPE = 3,
}

export type Asset = {
  src: string;
  width?: number;
  height?: number;
};

export type Media = {
  image: Asset;
  video?: Asset;
  text?: MediaText;
};

export type MediaText = {
  placekey?: string;
  titlekey: string;
  textkey: string;
  captionkey: string;
};

export type MapText = {
  titlekey: string;
  textkey: string;
  datekey: string;
};

export type MapHotSpot = {
  id: string;
  image?: Asset;
  video?: Asset;
  text: MediaText;
};

export type MediaContent = Media[];
export type MapContent = {
  introText: MapText;
  image?: Asset;
  hotspots: MapHotSpot[];
};

export type HotSpotType<
  TContent extends MediaContent | MapContent = MediaContent | MapContent
> = {
  type: HotSpotMediaType;
  labelkey: string;
  content: TContent;
};

export type SceneType = {
  hotspots?: HotSpotType[];
};

export type ChapterType = {
  titlekey: string;
  datekey: string;
  image?: Asset;
  scenes: SceneType[];
  // noiseScale: number;
};

export type ChapterIndex = 0 | 1 | 2 | 3;

export type ContentType = {
  chapters: ChapterType[];
};
