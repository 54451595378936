import "@/styles/index.css";
/////////////
////////////////////////////////////
//////////

import { createApp } from "vue";
import i18n from "@/core/i18n";
import App from "@/App.vue";
import cursor from "./directives/cursor";
import sound from "./directives/sound";

async function main() {
  const app = createApp(App);

  app.use(i18n);

  app.directive("cursor", cursor);
  app.directive("sound", sound);

  app.mount("#app");
}

main();
