import { setCursorHover } from "@/store/modules/Cursor";
import Viewport from "@/store/modules/Viewport";
import type { Directive } from "vue";

interface CursorDirectiveElement extends Element {
  __cursorMouseEnterHandler?: EventListener;
  __cursorMouseLeaveHandler?: EventListener;
}

/**
 * Custom directive to update AppCursor hover state on mouseover.
 * - Add `v-cursor.hover` to any element to update cursor state.
 * - Directive can be conditionally disabled with `false` as value, e.g. `v-cursor="false"`.
 */
const cursor: Directive<CursorDirectiveElement> = {
  mounted: (el, { value, modifiers }) => {
    const disabled = typeof value === "boolean" && !value;
    if (disabled) {
      el.removeEventListener("mouseenter", el.__cursorMouseEnterHandler);
      el.removeEventListener("mouseleave", el.__cursorMouseLeaveHandler);
      return;
    }

    const { hover } = modifiers;
    if (!Viewport.isTouch && hover) {
      el.__cursorMouseEnterHandler = () => setCursorHover(true);
      el.__cursorMouseLeaveHandler = () => setCursorHover(false);
      el.addEventListener("mouseenter", el.__cursorMouseEnterHandler);
      el.addEventListener("mouseleave", el.__cursorMouseLeaveHandler);
    }
  },
  unmounted: (el) => {
    el.removeEventListener("mouseenter", el.__cursorMouseEnterHandler);
    el.removeEventListener("mouseleave", el.__cursorMouseLeaveHandler);
  },
};

export default cursor;
