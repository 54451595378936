import { ref, computed, readonly, type MaybeRef } from "vue";
import { rad2deg } from "@/utils/math.utils";
import { round } from "@/utils/number.utils";

export default function useFoldedCorner(
  w: MaybeRef<number>,
  h: MaybeRef<number>
) {
  const _adjacent = ref(h);
  const _opposite = ref(w);

  const _hypothenus = computed(() =>
    Math.hypot(_adjacent.value, _opposite.value)
  );
  const _altitude = computed(
    () => (_adjacent.value * _opposite.value) / _hypothenus.value
  );
  const _angle = computed(() =>
    rad2deg(Math.asin(_opposite.value / _hypothenus.value))
  );
  const _y = computed(() =>
    Math.sqrt(Math.pow(_opposite.value, 2) - Math.pow(_altitude.value, 2))
  );

  const adjacent = computed(() => `${_adjacent.value}px`);
  const opposite = computed(() => `${_opposite.value}px`);
  const hypothenuse = computed(() => `${round(_hypothenus.value, 3)}px`);
  const altitude = computed(() => `${round(_altitude.value, 3)}px`);
  const angle = computed(() => `${round(_angle.value, 3)}deg`);
  const y = computed(() => `${round(_y.value, 3)}px`);
  const width = readonly(altitude);
  const height = readonly(hypothenuse);

  return {
    adjacent,
    opposite,
    hypothenuse,
    angle,
    altitude,
    y,
    width,
    height,
  };
}
