import { reactive, toRefs, onMounted, onUnmounted } from "vue";

interface MousePosition {
  x: number;
  y: number;
}

export default function useMousePosition() {
  const position = reactive<MousePosition>({ x: 0, y: 0 });

  function onMouseMove({ clientX, clientY }: MouseEvent) {
    position.x = clientX;
    position.y = clientY;
  }

  onMounted(() => {
    window.addEventListener("mousemove", onMouseMove, false);
  });

  onUnmounted(() => {
    window.removeEventListener("mousemove", onMouseMove, false);
  });

  return toRefs<MousePosition>(position);
}
