import { AppStateContext } from "@/services/states/AppStateMachine";
import AppService from "@/services/AppService";
import { useActor, useSelector } from "@xstate/vue";
import { StoryStateContext } from "./states/StoryStateMachine";

export function useAppState() {
  return useActor(AppService.state);
}

export function useStoryState() {
  return useActor(AppService.state.getSnapshot().context._story);
}

export function useAppContext<T extends ContextProps<AppStateContext>>(prop: T) {
  return useSelector(
    AppService.state,
    (state) => state.context[prop],
    (prev, next) => prev === next
  );
}

export function useStoryContext<T extends ContextProps<StoryStateContext>>(prop: T) {
  return useSelector(
    AppService.state.getSnapshot().context._story,
    (state) => state.context[prop],
    (prev, next) => prev === next
  );
}