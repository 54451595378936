import { reactive } from "vue";
import MobileDetect from "mobile-detect";
import breakpoints from "@/config/breakpoints.json";
import { isAvifSupported } from "@/utils/image.utils";

type Viewport = {
  md: MobileDetect;
  windowWidth: number;
  windowHeight: number;
  landscape: MediaQueryList;
  isMobile: boolean;
  isDesktop: boolean;
  isLandscape: boolean;
  isIOS: boolean;
  isTouch: boolean;
  isSafari: boolean;
  isAvifSupported: boolean;
  hasLocalStorage: boolean;
  hasNativeShare: boolean;
};

const Viewport = reactive<Viewport>({
  md: null,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  landscape: null,
  isMobile: false,
  isDesktop: false,
  isLandscape: false,
  isIOS: false,
  isTouch: false,
  isSafari: false,
  isAvifSupported: false,
  hasLocalStorage: false,
  hasNativeShare: false,
}) as Viewport;
export default Viewport;

Viewport.md = new MobileDetect(window.navigator.userAgent);
Viewport.landscape = window.matchMedia("(orientation: landscape)");
Viewport.isMobile = isMobile();
Viewport.isDesktop = isDesktop();
Viewport.isLandscape = isLandscape();
Viewport.isIOS = isIOS();
Viewport.isTouch = isTouch();
Viewport.isSafari = isSafari();
Viewport.hasLocalStorage = hasLocalStorageAvailable();
Viewport.hasNativeShare = hasNativeShare();
checkAvifSupport();

function isMobile() {
  return Viewport.md.mobile() !== null;
}

function isDesktop() {
  return Viewport.windowWidth >= parseInt(breakpoints.lg);
}

function isLandscape() {
  return Viewport.landscape.matches;
}

function isTouch() {
  return "ontouchstart" in window;
}

function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isSafari() {
  const ua = window.navigator.userAgent;
  return (
    /(iPhone|iPod|iPad).*AppleWebKit/i.test(ua) ||
    /^((?!chrome|android).)*safari/i.test(ua)
  );
}

function hasLocalStorageAvailable() {
  const test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function hasNativeShare() {
  return navigator.share !== undefined && isTouch();
}

async function checkAvifSupport() {
  Viewport.isAvifSupported = await isAvifSupported();
}

function onResize() {
  Viewport.windowWidth = window.innerWidth;
  Viewport.windowHeight = window.innerHeight;
  Viewport.isMobile = isMobile();
  Viewport.isDesktop = isDesktop();
  Viewport.isLandscape = isLandscape();

  calculateVH();
}

const calculateVH = () => {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
};

export function disposeViewport(): void {
  window.removeEventListener("resize", onResize);
  Viewport.landscape.removeEventListener("change", onResize);
}

window.addEventListener("resize", onResize);
Viewport.landscape.addEventListener("change", onResize);
calculateVH();

if (Viewport.isSafari) {
  document.documentElement.classList.add("is-safari");
}

document.documentElement.classList.add(
  Viewport.isTouch ? "is-touch" : "no-touch"
);
