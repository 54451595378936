import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { SplitText } from "gsap/SplitText";
// import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import frames from "@/utils/frameToTime";
import { deg2rad } from "@/utils/math.utils";

if (typeof window !== "undefined") {
  gsap.registerPlugin(
    CustomEase,
    ScrollTrigger,
    MorphSVGPlugin,
    SplitText,
    // ScrollSmoother,
    ScrollToPlugin,
    MotionPathPlugin
  );

  // gsap.defaults({ ease: "linear" });

  // Common easings from designers in AE
  CustomEase.create("ease.inOut", "0.25, 0.00, 0.75, 1.00");
  CustomEase.create("ease.in", "0.17, 0.00, 0.83, 0.83");
  CustomEase.create("ease.out", "0.17, 0.17, 0.25, 1.00");

  // Register Robert Penner's Easing Functions from AE Flow plugin (slightly different than GSAP ones)
  CustomEase.create("quad.inOut", "0.48, 0.04, 0.52, 0.96");
  CustomEase.create("quad.in", "0.26, 0.00, 0.60, 0.20");
  CustomEase.create("quad.out", "0.40, 0.80, 0.74, 1.00");

  CustomEase.create("cubic.inOut", "0.66, 0.00, 0.34, 1.00");
  CustomEase.create("cubic.in", "0.40, 0.00, 0.68, 0.06");
  CustomEase.create("cubic.out", "0.32, 0.94, 0.60, 1.00");

  CustomEase.create("quart.inOut", "0.76, 0.00, 0.24, 1.00");
  CustomEase.create("quart.in", "0.52, 0.00, 0.74, 0.00");
  CustomEase.create("quart.out", "0.26, 1.00, 0.48, 1.00");

  CustomEase.create("quint.inOut", "0.84, 0.00, 0.16, 1.00");
  CustomEase.create("quint.in", "0.64, 0.00, 0.78, 0.00");
  CustomEase.create("quint.out", "0.22, 1.00, 0.36, 1.00");

  CustomEase.create("expo.inOut", "0.90, 0.00, 0.10, 1.00");
  CustomEase.create("expo.in", "0.66, 0.00, 0.86, 0.00");
  CustomEase.create("expo.out", "0.14, 1.00, 0.34, 1.00");

  CustomEase.create("circ.inOut", "0.88, 0.14, 0.12, 0.86");
  CustomEase.create("circ.in", "0.54, 0.00, 1.00, 0.44");
  CustomEase.create("circ.out", "0.00, 0.56, 0.46, 1.00");

  CustomEase.create("back.inOut", "0.68, -0.55, 0.27, 1.55");
  CustomEase.create("back.in", "0.60, -0.28, 0.73, 0.04");
  CustomEase.create("back.out", "0.17, 0.89, 0.32, 1.27");

  // Prevent refresh on vertical resize on mobile
  // ScrollTrigger.config({ ignoreMobileResize: true });
  // ScrollTrigger.defaults({ anticipatePin: 1 });

  // Avoid animations lags
  // gsap.ticker.lagSmoothing(1000, 16)
}

// Shards floating effect
gsap.registerEffect({
  name: "float",
  effect: (
    targets: gsap.TweenTarget,
    { amp, rotation, duration }: FloatEffectConfig
  ) => {
    const angle = deg2rad(gsap.utils.random(0, 360));

    return gsap
      .timeline({
        defaults: {
          ease: "ease.inOut",
          repeat: -1,
          yoyo: true,
        },
      })
      .to(
        targets,
        {
          x: Math.cos(angle) * amp,
          y: Math.sin(angle) * amp,
          duration: duration[0],
        },
        0
      )
      .to(
        targets,
        {
          rotation,
          duration: duration[1],
        },
        0
      );
  },
  defaults: {
    amp: 20,
    rotation: () =>
      (2 + gsap.utils.random(0, 4, 1)) * gsap.utils.random([-1, 1]),
    duration: [frames(180), frames(210)],
  } as FloatEffectConfig,
  extendTimeline: true,
});

// Heading cinema effect
gsap.registerEffect({
  name: "heading",
  effect: (targets: gsap.DOMTarget, { stagger }: HeadingCinemaEffectConfig) => {
    const { words, chars } = new SplitText(targets, { type: "words, chars" });
    // split title in chunks, either by words, or half the number of letters
    let chunks: Element[] | Element[][] = [];
    if (words.length > 1) {
      chunks = words;
    } else {
      const half = Math.floor(chars.length / 2);
      chunks = [chars.slice(0, half), chars.slice(half)];
    }

    const tl = gsap.timeline();
    chunks.forEach((chunk, i) => {
      tl.from(
        chunk,
        {
          opacity: 0,
          duration: frames(1),
          ease: "none",
        },
        i * stagger
      );
    });

    return tl;
  },
  defaults: {
    stagger: frames(20),
  } as HeadingCinemaEffectConfig,
  extendTimeline: true,
});

const useGSAP = () => gsap;
export default useGSAP;
