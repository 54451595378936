/**
 * Convert radians to degrees
 * @see https://phuoc.ng/collection/1-loc/convert-radians-to-degrees/
 * @param rad
 * @returns
 */
export const rad2deg = (rad: number): number => (rad * 180) / Math.PI;

/**
 * Convert degrees to radians
 * @see https://phuoc.ng/collection/1-loc/convert-degrees-to-radians/
 * @param deg
 * @returns
 */
export const deg2rad = (deg: number): number => (deg * Math.PI) / 180.0;
