import { reactive } from "vue";

type Teleport = {
  position: number[],
}

const Teleports = reactive({
  tp: null as Teleport | null,
});

export default Teleports;

export function setCurrentTeleport(tp: Teleport) {
  Teleports.tp = tp;
}
