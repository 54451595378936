import { computed, onBeforeUnmount } from "vue";
import {
  watchPausable,
  toValue,
  unrefElement,
  notNullish,
  type MaybeComputedElementRef,
  type MaybeElement,
  type MaybeRefOrGetter,
} from "@vueuse/core";
import useGSAP from "@/composables/useGSAP";
import useMousePosition from "@/composables/useMousePosition";
import Viewport from "@/store/modules/Viewport";

interface UseMouseParallaxOptions extends gsap.TweenVars {
  amplitude?: number;
  opposite?: boolean;
}

export default function useMouseParallax(
  target:
    | MaybeComputedElementRef
    | MaybeComputedElementRef[]
    | MaybeRefOrGetter<MaybeElement[]>,
  options: UseMouseParallaxOptions = {}
) {
  const {
    amplitude = 20,
    opposite = true,
    duration = 0.8,
    ease = "power3",
  } = options;
  const direction = opposite ? -1 : 1;

  const targets = computed(() => {
    const value = toValue(target);
    const items = (Array.isArray(value) ? value : [value])
      .map(unrefElement)
      .filter(notNullish);
    return gsap.utils.toArray(items).filter(Boolean);
  });

  const { x: mouseX, y: mouseY } = useMousePosition();
  const gsap = useGSAP();

  let setTargetsX: gsap.QuickToFunc;
  let setTargetsY: gsap.QuickToFunc;

  const cleanup = () => {
    setTargetsX?.tween.kill();
    setTargetsY?.tween.kill();
  };

  const {
    stop: stopWatchMouse,
    pause: pauseWatchMouse,
    resume: resumeWatchMouse,
  } = watchPausable([mouseX, mouseY], ([x, y]) => {
    const offsetX = gsap.utils.mapRange(0, Viewport.windowWidth, -1, 1, x);
    const offsetY = gsap.utils.mapRange(0, Viewport.windowHeight, -1, 1, y);

    setTargetsX?.(offsetX * amplitude * direction);
    setTargetsY?.(offsetY * amplitude * direction);
  });

  const {
    stop: stopWatchTargets,
    pause: pauseWatchTargets,
    resume: resumeWatchTargets,
  } = watchPausable(
    () => targets.value,
    (targets) => {
      cleanup();
      if (targets.length > 0) {
        setTargetsX = gsap.quickTo(targets, "x", { duration, ease });
        setTargetsY = gsap.quickTo(targets, "y", { duration, ease });
      }
    },
    { immediate: true, flush: "post" }
  );

  const stop = () => {
    stopWatchMouse();
    stopWatchTargets();
    cleanup();
  };

  const pause = () => {
    pauseWatchMouse();
    pauseWatchTargets();
  };

  const resume = () => {
    resumeWatchMouse();
    resumeWatchTargets();
  };

  onBeforeUnmount(stop);

  return { stop, pause, resume };
}
