export const loadImage = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = document.createElement("img") as HTMLImageElement;

    function cleanup() {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    }

    function onLoad() {
      cleanup();
      resolve(img);
    }

    function onError(e: ErrorEvent) {
      cleanup();
      console.error(e);
      reject(new Error(`Failed to load image's src: ${src}`));
    }

    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    img.src = src;
  });
};

/**
 * Check if format is supported
 * @param format
 * @param dataUri
 * @returns
 */
export const isFormatSupported = async (format: string, dataUri: string) => {
  try {
    await loadImage(`data:image/${format};base64,${dataUri}`);
    return true;
  } catch (e) {
    console.log(format.toUpperCase() + " format not supported");
    return false;
  }
};

export const isAvifSupported = () =>
  isFormatSupported(
    "avif",
    "AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
  );
