import Signal from "@/core/Signal";
import { GLContext } from "nanogl/types";
import WebXRInputs from "@/webgl/xr/WebXRInputs";
import { quat, vec3 } from "gl-matrix";
import Deferred from "@/core/Deferred";
import gsap from "gsap";
import AppService from "@/services/AppService";
import Delay from "@/core/Delay";

export default class WebXRView {

  session: XRSession;
  referenceSpace: XRReferenceSpace | XRBoundedReferenceSpace;

  onSessionStarted: Signal<void>;
  onSessionEnded: Signal<void>;
  onVisibilityChange: Signal<boolean>;

  onRender = new Signal<number>()

  pose: XRViewerPose;
  inputs: WebXRInputs;

  previousTime: number;
  _rafId: number;

  rotation: quat;
  position: vec3;

  ended: boolean;
  lowFPS = true;

  webglLayer: XRWebGLLayer;

  _firstInit = true;

  _isSessionEnded = true;

  private _poseReadyDeferred = new Deferred()

  get poseReady() {
    return this._poseReadyDeferred.promise;
  }

  get gllayer(): XRWebGLLayer {
    return this.session.renderState.baseLayer;
  }

  constructor(
    public gl: GLContext
  ) {

    this.onSessionStarted = new Signal();
    this.onSessionEnded = new Signal();
    this.onVisibilityChange = new Signal();
    this.previousTime = window.performance.now();
    this.inputs = new WebXRInputs();

  }

  public static IsSessionSupportedAsync(sessionMode: XRSessionMode): Promise<boolean> {
    if (!(navigator as any).xr) {
      return Promise.resolve(false);
    }
    return ((navigator as unknown) as Navigator).xr.isSessionSupported(sessionMode);
  }

  async requestSession() {
    if (this.session) {
      // this.session.
      this.sessionStarted(this.session);
      return this.session;
    }
    this._poseReadyDeferred = new Deferred();
    return ((navigator as unknown) as Navigator).xr.requestSession("immersive-vr", {
      requiredFeatures: ["local-floor"],
      optionalFeatures: [
        "low-fixed-foveation-level",
      ]
    })
      .then(this.sessionStarted);
  }

  sessionStarted = async (session: XRSession) => {
    this.ended = false;
    this.session = session;

    const anySession = (session as any);
    // if (this.lowFPS && anySession.updateTargetFrameRate)
    if (anySession.updateTargetFrameRate)
      anySession.updateTargetFrameRate(72);

    try {
      await (this.gl as any).makeXRCompatible();
    } catch (err) {
      switch (err) {
        default:
          console.error(err);
          break;
      }
      this.session.end();
    }

    if (this._firstInit) {
      this.session.addEventListener("end", this.sessionEnded);
      this.session.addEventListener("visibilitychange", this.visibilityChange);


    }
    this.gl.viewport(0, 0, 500, 500);
    this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
    this.gl.clear(this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT | this.gl.STENCIL_BUFFER_BIT);
    this.webglLayer = new XRWebGLLayer(this.session, this.gl as WebGLRenderingContext, {
      antialias: true,
      depth: true,
      stencil: true,
      alpha: false,
      framebufferScaleFactor: 1
    })

    await this.session.updateRenderState({
      baseLayer: this.webglLayer,
      depthNear: 0.1,
      depthFar: 50
    });

    this.inputs.init(this.session);

    this.onSessionStarted.emit();

    this._firstInit = false;

  }

  refSpaceCreated = (refSpace: XRReferenceSpace | XRBoundedReferenceSpace) => {
    this._isSessionEnded = false;
    // console.log("Reference space created");

    this.rotation = quat.create();
    this.position = vec3.create();
    this.referenceSpace = refSpace;
    this.previousTime = window.performance.now();
    // Need the first frame to setup scene info
    this.session.requestAnimationFrame(this.init);
  }

  start() {

    this.session.requestReferenceSpace("bounded-floor")
      .then(this.refSpaceCreated)
      .catch(() => {
        // console.log("No floor reference space available");

        this.session.requestReferenceSpace("local-floor")
          .then(this.refSpaceCreated)
          .catch(() => {
            // console.log("No local-floor reference space available");

            this.session.requestReferenceSpace("local")
              .then(this.refSpaceCreated)
              .catch(() => {
                // console.log("No local reference space available");
              });
          });
      });

  }

  async endSession() {
    if(this._isSessionEnded)  return;
    this._isSessionEnded = true;
    this.session.cancelAnimationFrame(this._rafId);
    await this.session.end();
    this.webglLayer = null;
    this.session = null;
    this.ended = true;
  }

  init = (time: DOMHighResTimeStamp, frame: XRFrame) => {
    this.pose = frame.getViewerPose(this.referenceSpace);
    this.frame(time, frame);
    this._poseReadyDeferred.resolve();

  }

  _requestFrame() {
    if (this.ended)
      return;
    this._rafId = this.session.requestAnimationFrame(this.frame);
  }

  frame = (time: DOMHighResTimeStamp, frame: XRFrame) => {

    let dt = (time - this.previousTime) / 1000;
    this.previousTime = time;
    if (dt > 1 / 5 || dt < 1 / 180) {
      dt = 1 / 60;
    }
    gsap.ticker.tick();

    this.pose = frame.getViewerPose(this.referenceSpace);
    this.inputs.update(frame, this.referenceSpace);

    this.onRender.emit(dt);

    this._requestFrame();

  }

  sessionEnded = () => {
    this.ended = true;
    this.onSessionEnded.emit();
  }

  visibilityChange = async (e: XRSessionEvent) => {
    switch (e.session.visibilityState) {
      case "visible":
      case "visible-blurred":
        this.onVisibilityChange.emit(true);
        // console.log("VISIBLE");
        break;
      case "hidden":
        // console.log("HIDDEN");
        this.onVisibilityChange.emit(false);
        break;
    }
    AppService.Scene.activities.active[0].stop();
    await Delay(1000)
    AppService.Scene.activities.active[0].start();
  }

}