import { ref, Ref } from "vue";
import srtParser2 from "srt-parser-2";
import { AUDIO_ID } from "./AudioManager";
import AppService from "@/services/AppService";

export type SrtData = {
  id: string;
  startTime: string;
  startSeconds: number;
  endTime: string;
  endSeconds: number;
  text: string;
}
class SrtManager {
  renderUpdate = false;

  parsedSrts: Map<string, SrtData[]> = new Map();

  currentSrt: Ref<string | null> = null;

  parser: srtParser2

  private _currentString = "";
  private _currentSubId = "";

  constructor() {
    this.parser = new srtParser2();
    this.currentSrt = ref(null);
  }

  async loadSrtFile(id: AUDIO_ID | string, lang: string) {
    if (id === AUDIO_ID.UI || id === AUDIO_ID.MENU_MUSIC
      || id === AUDIO_ID.CHAPTER_1_MUSIC || id === AUDIO_ID.CHAPTER_2_MUSIC || id === AUDIO_ID.CHAPTER_3_MUSIC || id === AUDIO_ID.CHAPTER_4_MUSIC
      || id === AUDIO_ID.CHAPTER_2_SYNA_MUSIC
      || id === "shards_intro_chapter_3"
    ) return;
    const l = lang && lang.length > 0 ? `_${lang}` : '';
    const file = `/assets/audio/srt/${id}${l}.srt`;
    const response = await fetch(file);
    const data = await response.text();
    this.parsedSrts.set(id, this.parser.fromSrt(data));
  }

  get currentString() {
    return this._currentString;
  }

  sync(media: Howl | HTMLVideoElement, id: string, instanceId?: number) {

    const srts = this.parsedSrts.get(id);
    if (!srts) return;
    const currentTime = ((media as Howl).seek) ? (media as Howl).seek(instanceId) : (media as HTMLVideoElement).currentTime;
    const currentSrt = srts.find(srt => srt.startSeconds < currentTime && srt.endSeconds > currentTime);

    if (currentSrt && this._currentSubId !== currentSrt.id) {
      this._currentString = currentSrt.text;
      if (!AppService.isXr()) this.currentSrt.value = this._currentString;
      this._currentSubId = currentSrt.id;
      this.renderUpdate = true;
    }
    if (!currentSrt && this._currentSubId) {
      this.reset();
      this._currentSubId = "";
      this.renderUpdate = true;
    }
  }

  reset() {
    this._currentString = "";
    this._currentSubId = "";
    this.currentSrt.value = "";

  }
}

const _instance = new SrtManager();

export default _instance;