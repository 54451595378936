import AppResources from "@/services/AppResources";
import {
  CreateAppStateInterpreter,
  AppStateInterpreter,
} from "@/services/states/AppStateMachine";
import type GLApp from "@webgl/GLApp";
import type GameScene from "@webgl/GameScene";

export class AppServiceClass {
  private static _instance: AppServiceClass;

  glapp: GLApp;
  state: AppStateInterpreter;

  resources: AppResources;

  static getInstance() {
    if (!AppServiceClass._instance) {
      AppServiceClass._instance = new AppServiceClass();
    }
    return AppServiceClass._instance;
  }

  get Scene(): GameScene {
    return this.glapp.renderer.scene;
  }

  constructor() {
    this.state = CreateAppStateInterpreter();

    if (process.env.VUE_APP_COMING_SOON === "true") return;
    this.resources = new AppResources();
  }

  async createGlApp() {
    const module = await import("@webgl/GLApp");
    await import("@webgl/Features");
    this.glapp = new module.default();
    await this.glapp.createGlAppElements();
  }

  async start() {
    await this.createGlApp();
    this.state.start();
    this.state.send({ type: "INIT" });

    if (process.env.VUE_APP_COMING_SOON === "true") return;
    this.glapp.start();

/////////////////
////////////////////////////////////////////////////////////
//////////////
  }

  getStoryState() {
    return this.state.getSnapshot().context._story;
  }

  getStoryContext() {
    return this.getStoryState().getSnapshot().context;
  }

  isXr() {
    return this.state.getSnapshot().context.isXR && this.glapp.xrmodule.Ready;
  }

  isXrSupported() {
    return this.state.getSnapshot().context.isXrSupported;
  }

///////////////
////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////
///
////////////
}

const AppService = AppServiceClass.getInstance();

export default AppService;
