var fn = function( obj ){
var __t,__p='';
__p+='';
 if(obj.slot === 'pv' ){ 
__p+='\n\nIN vec2 aTexCoord0;\nOUT vec2 vTexCoord;\n\n';
 } 
__p+='\n\n';
 if(obj.slot === 'postv' ){ 
__p+='\n\nvTexCoord = aTexCoord0;\n\n';
 } 
__p+='';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
