/**
 * Mux HLS base URL for streaming videos.
 * HLS URLs end with the extension `.m3u8`
 */
export const MUX_STREAM_URL = "https://stream.mux.com/";

/**
 * Preloader minimum duration, even if App is already loaded.
 * In milliseconds.
 */
export const PRELOADER_MIN_DURATION = 4833;

/**
 * Delay to wait on Landing before entering Homepage.
 * In milliseconds.
 */
export const LANDING_IDLE_DELAY = 3000;

/**
 * Media closing animation duration.
 * In milliseconds.
 */
export const MEDIA_CLOSING_DURATION = 500;

/**
 * Add a delay before launching lazy load to avoid lag in home animation
 * In milliseconds.
 */
export const LAZY_LOAD_ANIM_DELAY = 8000;
