import { reactive } from "vue";

type Theme = "dark" | "light";
type LayoutComponent = "header" | "footer";

type Layout = Record<LayoutComponent, Theme>;

const LayoutTheme = reactive<Layout>({
  header: "dark",
  footer: "dark",
});

function setLayoutTheme(theme: Theme, component?: LayoutComponent) {
  const components: LayoutComponent[] = component
    ? [component]
    : ["header", "footer"];
  components.forEach((component) => (LayoutTheme[component] = theme));
}

function setHeaderTheme(theme: Theme) {
  setLayoutTheme(theme, "header");
}

function setFooterTheme(theme: Theme) {
  setLayoutTheme(theme, "footer");
}

export {
  LayoutTheme as default,
  setLayoutTheme,
  setHeaderTheme,
  setFooterTheme,
};
