import {
  createI18n,
  type I18nOptions,
  type LocaleMessages,
  type VueMessageType,
} from "vue-i18n";
import { getLocale } from "@/config/getLocale";

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
    "sync"
  );

  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default || locales(key);
    }
  });

  return messages;
}

const locale = getLocale();

export function setupI18n(options: I18nOptions = {}) {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages() as LocaleMessages<VueMessageType>,
    warnHtmlInMessage: "off",
    ...options,
  });

  document.querySelector("html").setAttribute("lang", locale);

  return i18n;
}

export default setupI18n();

/**
 * Replace all asterisk-wrapped words with <b> tags.
 * @param str
 * @returns
 */
export const boldify = (str?: string) =>
  str?.replace(/\*([^\*]*)\*/g, "<b>$1</b>");
