interface Chapter {
  en: number;
  de: number;
  [key: string]: number;
}

interface VideoTimings {
  chapters: Chapter[];
}

const readingTimings: VideoTimings = {
  chapters: [
    {
      en: 22,
      de: 22
    },
    {
      en: 29,
      de: 29
    },
    {
      en: 19,
      de: 19
    },
    {
      en: 29,
      de: 29
    }
  ]
};

const videoTimings: VideoTimings = {
  chapters: [
    {
      en: 6.3,
      de: 7.15
    },
    {
      en: 5.25,
      de: 6.22
    },
    {
      en: 16.2,
      de: 15.5
    },
    {
      en: 4,
      de: 4
    }
  ]
};

function getReadingTiming(index: number, lang: string): number {
  const chapter = readingTimings.chapters[index];
  return chapter ? chapter[lang] : 0;
}

function getVideoTiming(index: number, lang: string): number {
  const chapter = videoTimings.chapters[index];
  return chapter ? chapter[lang] : 0;
}


export { videoTimings, getReadingTiming, getVideoTiming };