import { HexToNewVec3, ParseCSS } from "@webgl/core/Color";
import colors from "@/config/colors.json";

export const YELLOW_HEX_STR = colors.yellow;
export const YELLOW = HexToNewVec3(ParseCSS(colors.yellow));
export const BLACK_HEX_STR = colors.black;
export const BLACK_HEX = ParseCSS(BLACK_HEX_STR);
export const BLACK = HexToNewVec3(BLACK_HEX);
export const BLACK_INT_HEX = 0x393939;
export const BLACK_INT = HexToNewVec3(BLACK_INT_HEX);
export const LIGHT_GREY = HexToNewVec3(0x898987);
export const RED = HexToNewVec3(0xFF0000);
export const LIGHT_RED = HexToNewVec3(0xF94343);

export const CAM_MOBILE_FOV_INTRO = 0.8;
export const CAM_MOBILE_FOV_START = 1.52;