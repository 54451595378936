import Delay from "@/core/Delay";
import { assign, createMachine, sendParent, State } from "xstate";
import { send } from "xstate/lib/actionTypes";
import AppService from "../AppService";
import { getLocalStream } from "@/core/SpeechToTextService";


export enum XRStateEnum {
  INACTIVE = 'inactive',
}

export type XRStateValue = `${XRStateEnum}`


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface XRStateContext { }


export type XRStateType = {
  value: XRStateValue
  context: XRStateContext
}


export type XRStateEvent = {
  type: "REQUEST_SESSION" | "SESSION_END" | "EXIT" | "PLACED"
}


export type XRState = State<XRStateContext, XRStateEvent, any, XRStateType>



const XRStateMachine = createMachine({

  id: "XR_fsm",

  initial: "inactive",

  context: {
  },


  states: {

    inactive: {
      entry: ['setupStdActivities', 'notifyChange', 'xrCancel'],
      on: {
        REQUEST: 'requesting'
      }
    },


    requesting: {

      on: {
        SESSION_END: 'inactive',
        EXIT: 'inactive'
      },

      invoke: {
        src: 'requestXR',
        onDone: 'active',
        onError: 'inactive'
      }
    },


    active: {
      entry: ['setupXrActivities', 'notifyChange', 'xrStarted'],
      on: {
        SESSION_END: '.stop',
        EXIT: 'inactive'
      },
      initial: 'place',

      states: {
        place: {
          on: {
            PLACED: 'play'
          }
        },
        play: {
          on: {
            REQUEST: '#XR_fsm.requesting'
          }
        },
        stop: {
          invoke: {
            src: 'endXR',
            onDone: 'stopped'
          }
        },
        stopped: {
          entry: ['xrCancel'],
          always: '#XR_fsm.inactive'
        }
      }
    },

  }

}, {

  services: {
    requestXR: async () => {
      // console.log("requesting XR session");
      await getLocalStream();
      return AppService.glapp.requestXR();
    },

    endXR: () => {
      // console.log("end XR session");
      return AppService.glapp.cancelXR();
    }
  },

  actions: {

    notifyChange: sendParent('XR_STATE_CHANGE'),
    xrStarted: sendParent("XR_RESPONSE"),

    setupXrActivities() {
      sendParent('XR_STATE_CHANGE');
      // console.log("setting up XR activities");
    },

    xrCancel() {
      sendParent("XR_CANCEL");
    },

    setupStdActivities() {
      // console.log("setting up STD activities");
    }
  }

})


export type XRStateMachineType = typeof XRStateMachine


export default XRStateMachine