import { Howl, HowlCallback, Howler, HowlOptions } from "howler";
import Delay from "@/core/Delay";
import Signal from "@/core/Signal";
import SrtManager from "./SrtManager";

import UISprite from "./ui.json";
import Chapter0Sprite from "./chapter_0_en.json";
import Chapter1Sprite from "./chapter_1_en.json";
import Chapter2Sprite from "./chapter_2_en.json";
import Chapter0SpriteDE from "./chapter_0_de.json";
import Chapter1SpriteDE from "./chapter_1_de.json";
import Chapter2SpriteDE from "./chapter_2_de.json";
import { getLocale } from "@/config/getLocale";
import { getLoopValue, getVoiceEndValue } from "./audioTimings";
import AppService from "@/services/AppService";
import Viewport from "@/store/modules/Viewport";

type SpriteData = {
  start: number;
  duration: number;
}

type Bounds = { bounds: [number, number] };

export enum AUDIO_ID {
  UI = "ui",
  CHAPTER_0 = "chapter_0",
  CHAPTER_1 = "chapter_1",
  CHAPTER_2 = "chapter_2",
  CHAPTER_3 = "chapter_3",
  MENU_MUSIC = "../assets/audio/MUSIC_MENU-CREDITS",
  CHAPTER_1_MUSIC = "../assets/audio/MUSIC_CH1-MAIN",
  CHAPTER_2_MUSIC = "../assets/audio/MUSIC_CH2-MAIN",
  CHAPTER_2_SYNA_MUSIC = "../assets/audio/MUSIC_CH2-SYNAGOGUE",
  CHAPTER_3_MUSIC = "../assets/audio/MUSIC_CH3-MAIN",
  CHAPTER_4_MUSIC = "../assets/audio/MUSIC_CH4-MAIN",
}

export const CHAPTER_MUSIC: AUDIO_ID[] = [
  AUDIO_ID.CHAPTER_1_MUSIC,
  AUDIO_ID.CHAPTER_2_MUSIC,
  AUDIO_ID.CHAPTER_3_MUSIC,
  AUDIO_ID.CHAPTER_4_MUSIC,
];

export class AudioLib {
  _muted = false;

  _audio: Map<AUDIO_ID, Howl> = new Map<AUDIO_ID, Howl>();
  _spriteData: Map<string, SpriteData> = new Map<string, SpriteData>();

  lang: string;
  onMuted: Signal<boolean>;

  musicInstance: number | null = null;
  storyId: AUDIO_ID | null = null;

  currentSceneSprite: string | null = null;
  storyinstanceId: number | null = null;

  constructor() {
    Howler.autoSuspend = false;
    Howler.html5PoolSize = 0;
    Howler.usingWebAudio = true;

    this.lang = getLocale();
    this.onMuted = new Signal<boolean>();

    this.setup(AUDIO_ID.UI, UISprite as any);
    this.setup(AUDIO_ID.CHAPTER_0, this.lang === "de" ? Chapter0SpriteDE as any : Chapter0Sprite as any);
    this.setup(AUDIO_ID.CHAPTER_1, this.lang === "de" ? Chapter1SpriteDE as any : Chapter1Sprite as any);
    this.setup(AUDIO_ID.CHAPTER_2, this.lang === "de" ? Chapter2SpriteDE as any : Chapter2Sprite as any);

    // Ambients setup
    this.addAudio(AUDIO_ID.MENU_MUSIC, { loop: true, volume: 0 });
    this.addAudio(AUDIO_ID.CHAPTER_1_MUSIC, { loop: true, volume: 0, preload: false });
    this.addAudio(AUDIO_ID.CHAPTER_2_MUSIC, { loop: true, volume: 0, preload: false });
    this.addAudio(AUDIO_ID.CHAPTER_2_SYNA_MUSIC, { loop: true, volume: 0, preload: false });
    this.addAudio(AUDIO_ID.CHAPTER_3_MUSIC, { loop: true, volume: 0, preload: false });
    this.addAudio(AUDIO_ID.CHAPTER_4_MUSIC, { loop: true, volume: 0, preload: false });

    document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  handleVisibilityChange() {
    const isHidden = document.visibilityState === "hidden";
    const state = AppService.getStoryState().getSnapshot();
    if (isHidden) {
      const wasMuted = this.muted;
      this.setMute(true);
      if (this.storyId !== null && state.matches("story.scene.default")) {
        this.pause(this.storyId, this.storyinstanceId);
      }
      const onceVisible = () => {
        !wasMuted && this.setMute(false);
        if (this.storyId !== null && state.matches("story.scene.default")) {
          // this.play(this.storyId, false);
          this.playSprite(this.storyId, this.currentSceneSprite, this.getProgress(this.storyId, this.storyinstanceId, this.currentSceneSprite));
        }
        document.removeEventListener("visibilitychange", onceVisible);
      };
      document.addEventListener("visibilitychange", onceVisible);
    }
  }

  getVolume() {
    return Howler.volume();
  }

  get muted() {
    return this._muted;
  }

  set muted(muted: boolean) {
    this.setMute(muted);
  }

  setMute(muted: boolean) {
    this._muted = muted;
    Howler.mute(muted);
    this.onMuted.emit(muted);
  }

  getAudio(id: AUDIO_ID) {
    return this._audio.get(id);
  }

  // LOAD

  async load(id: AUDIO_ID) {
    const howl = this._audio.get(id);
    if (howl) {
      await Promise.all([howl.load(), SrtManager.loadSrtFile(id, this.lang)]);
    }

  }

  unload(id: AUDIO_ID) {
    const howl = this._audio.get(id);
    if (howl) {
      howl.unload();
    }
  }

  async earlyLoad() {
    this.load(AUDIO_ID.UI);
    this.load(AUDIO_ID.MENU_MUSIC);
  }


  // SETUP

  setup(id: AUDIO_ID, sprite: any) {
    sprite.preload = false;
    if (Viewport.isMobile) {
      if (sprite.src.length > 0) {
        sprite.src[0] = (sprite.src[0] as String).replace("/assets/audio/", "/assets/audio/mobile/");
      }
    }
    const howl = new Howl(sprite);
    this._audio.set(id, howl);

    for (const [key, value] of Object.entries(sprite.sprite) as Array<[string, [number, number]]>) {
      this._spriteData.set(`${id}_${key}`, {
        start: value[0] * 0.001,
        duration: value[1] * 0.001
      })
    }
  }

  addAudio(id: AUDIO_ID, opts: Partial<HowlOptions & Bounds> = {}) {
    const _opts = {
      preload: true,
      autoplay: false,
    };

    if (opts.bounds) {
      opts.sprite = { main: [opts.bounds[0], opts.bounds[1] - opts.bounds[0]] };
    }
    Object.assign(_opts, opts);

    let fileName: AUDIO_ID | string = id;

    if (Viewport.isMobile) {
      fileName = fileName.replace("/assets/audio/", "/assets/audio/mobile/");
    }

    const howl = new Howl({
      src: [`/audio/${fileName}.mp3`],
      ..._opts,
    });

    this._audio.set(id, howl);

    return howl;
  }

  // PLAY

  play(id: AUDIO_ID, restart = true): number {
    const howl = this._audio.get(id);
    if (!howl || howl.playing()) return;
    restart && howl.seek(0);
    return howl.play();
  }

  fadeIn(id: AUDIO_ID, volume = 1.0, duration = 1000): Promise<number> {
    const howl = this._audio.get(id);
    if (!howl || howl.playing()) return;
    if (!howl.playing()) this.musicInstance = this.play(id, false);
    // console.log("FADE IN", id, this.musicInstance);
    howl.off("fade");
    howl.fade(howl.volume(), volume, duration);

    return new Promise((resolve) => {
      howl.once("fade", resolve);
    });
  }

  fadeOut(id: AUDIO_ID, duration = 1000): Promise<number> {
    const howl = this._audio.get(id);
    if (!howl) return;
    // console.log("FADE OUT", id);
    howl.off("fade");
    howl.fade(howl.volume(), 0, duration);
    return new Promise((resolve) => {
      howl.once("fade", resolve);
    });
  }

  fadeOutAndStop(id: AUDIO_ID, audioInstance: number, duration = 1000): Promise<void> {
    const howl = this._audio.get(id);
    if (!howl || !howl.playing()) return Promise.resolve();
    // console.log("FADE OUT AND STOP", id, audioInstance);
    howl.off("fade");
    howl.fade(howl.volume(), 0, duration, audioInstance);
    return new Promise<void>((resolve) => {
      howl.once("fade", () => {
        howl.stop(audioInstance);
        resolve();
      });
    });
  }

  playSprite(id: AUDIO_ID, spriteName: string, progress?: number): number {
    const howl = this._audio.get(id);

    if (!howl) return;

    const instanceId = howl.play(spriteName);

    this.currentSceneSprite = spriteName;
    this.storyinstanceId = instanceId;

    if (progress) {
      const sprite = this._spriteData.get(`${id}_${spriteName}`);
      if (sprite) {
        howl.seek(sprite.start + progress, instanceId);
      }
    }

    return instanceId;
  }

  async playUI(name: string, delay = 0.0, volume = 1.0, cancelDuplicate = false) {
    await Delay(delay * 1000);
    const howl = this._audio.get(AUDIO_ID.UI);
    if (!howl) return;
    if (howl.playing() && cancelDuplicate) howl.stop();

    const soundId = howl.play(name);
    howl.volume(volume, soundId);
  }

  playInstance(id: AUDIO_ID, instanceId: number) {
    const howl = this._audio.get(id);

    if (!howl || howl.playing()) return;

    return howl.play(instanceId);
  }

  // STOP

  stop(id: AUDIO_ID, instanceId?: number) {
    this._audio.get(id).stop(instanceId);
  }

  // PAUSE

  pause(id: AUDIO_ID, instanceId?: number) {
    this._audio.get(id).pause(instanceId);
  }

  // EVENTS

  on(id: AUDIO_ID, event: string, callback: HowlCallback, instanceId?: number) {
    this._audio.get(id).on(event, callback, instanceId);
  }

  off(id: AUDIO_ID, event: string, callback: HowlCallback, instanceId?: number) {
    this._audio.get(id).off(event, callback, instanceId);
  }

  // PROGRESS

  getProgress(id: AUDIO_ID, instanceId?: number, spriteName?: string) {
    const howl = this._audio.get(id);
    if (!howl) return 0;

    if (!instanceId) {
      return howl.seek();
    }

    SrtManager.sync(howl, id, instanceId);

    const start = this._spriteData.get(`${id}_${spriteName}`)?.start || 0
    return Math.max(howl.seek(instanceId) - start, 0);
  }

  getDuration(id: AUDIO_ID, spriteName?: string) {
    const howl = this._audio.get(id);
    if (!howl) return 0;

    if (!spriteName) {
      return howl.duration();
    }

    return this._spriteData.get(`${id}_${spriteName}`)?.duration || 0;
  }

  check(id: AUDIO_ID, audioInstance: number, spriteName: string, chapterId: number, sceneId: number) {
    // console.log("CHECK", id, audioInstance, spriteName, chapterId, sceneId)
    const howlMusicMenu = this._audio.get(AUDIO_ID.MENU_MUSIC);
    const howlChapter1Music = this._audio.get(AUDIO_ID.CHAPTER_1_MUSIC);
    const howlChapter2Music = this._audio.get(AUDIO_ID.CHAPTER_2_MUSIC);
    const howlChapter2SynaMusic = this._audio.get(AUDIO_ID.CHAPTER_2_SYNA_MUSIC);

    // console.log(howlMusicMenu?.playing(), howlChapter1Music?.playing(), howlChapter2Music?.playing(), howlChapter2SynaMusic?.playing());
  }

  getVoiceEndValue(chapterId: number, sceneId: number) {
    const value = getVoiceEndValue(chapterId, sceneId, this.lang);
    // console.log("GET VOICE END VALUE", chapterId, sceneId, this.lang, value);
    return value;
  }

  getLoopValue(chapterId: number, sceneId: number) {
    // console.log("GET LOOP VALUE", chapterId, sceneId, this.lang);
    return getLoopValue(chapterId, sceneId, this.lang);
  }
}

const _instance = new AudioLib();

export default _instance;
